import React, {useState } from 'react';
import { FaCopy } from "react-icons/fa";
import { FiFacebook, FiTwitter, FiLinkedin } from 'react-icons/fi';
import { HiOutlineMail } from "react-icons/hi";
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment-timezone'
import CryptoJS from "crypto-js";
import { secretPass } from '../env';

export const formatPhoneNumber = (phoneNumberString)  => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

export const ShareProfilePropup = ({ ProfileKey, shareLinkUrl }) => {
  const [copied, setCopied] = useState(false);
  return(
    <div className="modal fade" id="personalInviteModal" tabIndex="-1" aria-labelledby="personalInviteModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0 pb-0">
            <h5 className="modal-title">Your Personal Invite Link</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body pt-0">
            <p>For every successful signup, you will get 20 additional credits.</p>
            <div className="input-group copyinputcontainer mb-3">
              <input type="text" readOnly={true}  className="form-control" defaultValue={`${window.location.origin}/register?invite=${ProfileKey}`} />
              <div className="input-group-prepend">
                {copied && <span className="tooltip-text">Copied!</span>}
                <CopyToClipboard text={`${window.location.origin}/register?invite=${ProfileKey}`} onCopy={() => { 
                  setCopied(true);
                  setTimeout( () => {
                    setCopied(false);
                  },500)
                }}>
                  <div className="input-group-text btn px-3 btn-primary text-white" id="btnGroupAddon"><FaCopy size="1.5em" /></div>
                </CopyToClipboard>
              </div>
            </div>
            
            <div className="buttonsGroup">
              <a href={`mailto:susan@3for3networking.com?subject=Invite%20Link&body=You%20are%20invited%20to%20a%20join%20with%20me%20${shareLinkUrl}`} className="btn btn-outline-primary" target='_blank' rel="noreferrer"><HiOutlineMail/> Email</a>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareLinkUrl}`} rel="noreferrer"  target="_blank" className="btn btn-outline-primary"><FiFacebook/> Facebook</a>
              <a href={`https://www.linkedin.com/shareArticle?url=${shareLinkUrl}&title=3For3%20Networking`} rel="noreferrer"  target="_blank" className="btn btn-outline-primary"><FiLinkedin/> Linkedin</a>
              <a href={`https://twitter.com/intent/tweet?text=${shareLinkUrl}`} rel="noreferrer"  target="_blank" className="btn btn-outline-primary"><FiTwitter/> Twitter</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const checkDateIsPast = (meetingDate) => {
  moment.tz.setDefault("America/New_York")
  const meetingdate = moment(meetingDate).toISOString();
  const todaysDate = new Date(new Date().toLocaleString("en-US", {
    timeZone:"America/New_York",
  })).toISOString()

  // return moment().unix() < moment(meetingDate).unix()
  return moment(moment().unix()).isAfter(moment(meetingDate).unix())
}

export const generateTimeOptions = (minuteIncrement = 15) => {
  const timeOptions = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += minuteIncrement) {
      // const formattedHour = (hour % 12 || 12).toString().padStart(2, "0");
      const formattedHour = (hour % 12 || 12).toString();
      const formattedMinute = minute.toString().padStart(2, "0");
      const ampm = hour < 12 ? "AM" : "PM";
      const time_12_hours = `${formattedHour}:${formattedMinute} ${ampm}`;
      const time_24_hours = `${hour}:${formattedMinute}`;
      // const isDisabled =
      //   hour < currentHour ||
      //   (hour === currentHour && minute < currentMinute + 15);
      timeOptions.push(
        <option key={time_12_hours} value={time_24_hours} disabled={false}>
          {time_12_hours}
        </option>
      );
    }
  }

  return timeOptions;
}; 

export const generateEncryptKey = (key) => {
  return CryptoJS.AES.encrypt(key, secretPass).toString()
}