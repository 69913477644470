import React, { useCallback, useEffect, useState } from "react";
import "./schedule.styles.scss";

import { connect, useSelector } from "react-redux";
import axios from "axios";
import clientConfig from "../../env";
import moment from "moment/moment";
import "moment-timezone";

import { Link } from "react-router-dom"

import { SiJitsi } from "react-icons/si";
import { BsTelephone } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { GoGear } from "react-icons/go";
import { IoMapOutline } from "react-icons/io5";

//import Kalend, { CalendarView } from 'kalend' // import component
//import 'kalend/dist/styles/index.css'; // import styles

import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

//Components
import MainContainer from "../../components/main-container/main-container.component";
import { formatPhoneNumber } from "../../utilities/utilities";

moment.tz.setDefault("America/New_York");

const localizer = momentLocalizer(moment);

// class Schedule extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       events: [],
//       eventPopupOpen: false,
//       singleEventData: null,
//       // calendarView: CalendarView.MONTH,
//       windowWidth: null,
//     };
//   }

//   componentDidMount = () => {
//     this.getScheduledMeetings();
//     // window.addEventListener("resize", this.handleWindowSizeChange);
//   };

//   componentWillUnmount = () => {
//     // window.removeEventListener("resize", this.handleWindowSizeChange);
//   };

//   // handleWindowSizeChange = () => {
//   //   this.setState(
//   //     {
//   //       windowWidth: window.innerWidth,
//   //     },
//   //     () => {
//   //       if (parseInt(this.state.windowWidth) < 768) {
//   //         this.setState({
//   //           calendarView: CalendarView.AGENDA,
//   //         });
//   //       }
//   //     }
//   //   );
//   // };

//   getScheduledMeetings = () => {
//     axios
//       .get(
//         `${clientConfig.siteUrl}wl/v1/scheduled_meetings/${this.props.userInfo.userId}`
//       )
//       .then((response) => {
//         if (response.status === 200) {
//           let events = [];

//           JSON.parse(response.data.scheduled_meetings).map((item, index) => {
//             let eventitem = {
//               id: index,
//               startAt: moment.utc(item.start_date_time).format(),
//               endAt: moment.utc(item.end_date_time).format(),
//               start: moment.utc(item.start_date_time).format(),
//               end: moment.utc(item.end_date_time).format(),
//               summary: `${item.start_time} meeting with ${item.user_name}`,
//               title: `${item.start_time} meeting with ${item.user_name}`,
//               color: "transparent",
//               meetinglink: item.meetinglink,
//               username: item.user_name,
//               userid: item.user_id,
//               meetingdate: item.meeting_date,
//               duration: item.duration,
//               start_time: item.start_time,
//               end_time: item.end_time,
//               timezoneStartAt: "America/New_York",
//               communicationType: item?.communicationType || null,
//             };

//             events = [...events, eventitem];
//           });

//           console.log({events})

//           this.setState({
//             events: events,
//           });
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   onEventClick = (data) => {
//     this.setState({
//       eventPopupOpen: !this.state.eventPopupOpen,
//       singleEventData: data,
//     });
//   };

//   render() {
//     return (
//       <MainContainer>
//         <div
//           className={`schedule-calendar-container py-4${
//             this.state.eventPopupOpen ? " popup_open" : ""
//           }`}
//           style={{ height: "calc(100% - 80px)" }}
//         >
//           <div className="row">
//             <div className="col-12 col-md-6">
//               <h1 className="title">Schedule Calendar</h1>
//             </div>
//             <div className="col-12 col-md-6 text-right">
//               <button className="btn btn-primary btn-sm rounded-sm">
//                 <GoGear className="mr-1" size="1.5em" />
//                 Settings
//               </button>
//             </div>
//           </div>

//           {/* <Kalend
//             onEventClick={this.onEventClick}
//             events={this.state.events}
//             initialDate={new Date().toISOString()}
//             hourHeight={60}
//             initialView={CalendarView.AGENDA}
//             disabledViews={CalendarView[("day", "three days")]}
//             disableMobileDropdown={true}
//             timeFormat={"12"}
//             weekDayStart={"Monday"}
//             calendarIDsHidden={["work"]}
//             language={"en"}
//             style={{
//               primaryColor: "indigo",
//               baseColor: "#3d3c3c",
//               inverseBaseColor: "#f2ecec",
//             }}
//             timezone="America/New_York"
//           /> */}

//           <Calendar
//             localizer={localizer}
//             defaultDate={new Date()}
//             defaultView="month"
//             views={["month", "agenda"]}
//             events={this.state.events}
//             style={{ height: "calc(100vh - calc(45px + 2em))" }}
//             onSelectEvent={this.onEventClick}
//             popup={true}
//           />
//         </div>

//         {/* Modal */}

//         <div
//           className={`modal fade ${this.state.eventPopupOpen ? "show" : ""}`}
//           data-backdrop="static"
//           data-keyboard="false"
//           tabIndex="-1"
//           aria-labelledby="staticBackdropLabel"
//           aria-hidden="true"
//           style={
//             this.state.eventPopupOpen
//               ? { display: "block" }
//               : { display: "none" }
//           }
//         >
//           <div className="modal-dialog modal-dialog-centered">
//             <div className="modal-content">
//               <div className="modal-header border-0">
//                 <button
//                   onClick={() =>
//                     this.setState({
//                       eventPopupOpen: !this.state.eventPopupOpen,
//                     })
//                   }
//                   type="button"
//                   className="close pt-2 pb-0 px-2"
//                   data-dismiss="modal"
//                   aria-label="Close"
//                 >
//                   <IoCloseOutline />
//                 </button>
//               </div>
//               <div className="modal-body pt-0">
//                 <div className="row">
//                   <div className="col-1">
//                     <span className="badge badge-primary p-2 mt-2">
//                       <span className="sr-only">Success</span>
//                     </span>
//                   </div>
//                   <div className="col-11">
//                     <p className="lead mb-0">3For3 Meeting</p>
//                     <small className="text-muted d-block">
//                       {this.state.singleEventData?.meetingdate &&
//                         moment(this.state.singleEventData?.meetingdate).format(
//                           "dddd, MMMM Do YYYY"
//                         )}
//                     </small>
//                     <small className="text-muted d-block">{`${this.state?.singleEventData?.start_time} - ${this.state?.singleEventData?.end_time}`}</small>
//                   </div>

//                   {this.state?.singleEventData?.communicationType ===
//                   "phone" ? (
//                     <>
//                       <div className="col-1 my-4">
//                         <BsTelephone style={{ fontSize: "1.3em" }} />
//                       </div>
//                       <div className="col-11 my-4">
//                         {this.state?.singleEventData?.meetinglink ? (
//                           <a
//                             href={`tel:${this.state?.singleEventData?.meetinglink}`}
//                             rel="noreferrer"
//                             className="btn btn-primary px-4"
//                           >
//                             {formatPhoneNumber(
//                               this.state?.singleEventData?.meetinglink
//                             )}
//                           </a>
//                         ) : null}
//                       </div>
//                     </>
//                   ) : (
//                     <>
//                       <div className="col-1 my-4">
//                         <SiJitsi style={{ fontSize: "1.5em" }} />
//                       </div>
//                       <div className="col-11 my-4">
//                         {this.state?.singleEventData?.meetinglink ? (
//                           <a
//                             href={this.state?.singleEventData?.meetinglink}
//                             target="_blank"
//                             rel="noreferrer"
//                             className="btn btn-primary px-4"
//                           >
//                             Join the Meeting
//                           </a>
//                         ) : null}
//                       </div>
//                     </>
//                   )}

//                   <div className="col-1">
//                     <FiUsers />
//                   </div>
//                   <div className="col-11">
//                     <p className="m-0 text-muted">
//                       {this.state?.singleEventData?.username}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </MainContainer>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   userInfo: state.user,
// });

// export default connect(mapStateToProps)(Schedule);


const MeetingLinkUI = ({ communicationType, meetinglink }) => {
  console.log({ communicationType });
  switch (communicationType) {
    case "phone":
      return (
        <>
          <div className="col-1 my-4">
            <BsTelephone style={{ fontSize: "1.3em" }} />
          </div>
          <div className="col-11 my-4">
            {meetinglink ? (
              <a
                href={`tel:${meetinglink}`}
                rel="noreferrer"
                className="btn btn-primary px-4"
              >
                {formatPhoneNumber(meetinglink)}
              </a>
            ) : null}
          </div>
        </>
      );
    case "video":
      return (
        <>
          <div className="col-1 my-4">
            <SiJitsi style={{ fontSize: "1.5em" }} />
          </div>
          <div className="col-11 my-4">
            {meetinglink ? (
              <a
                href={meetinglink}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary px-4"
              >
                Join the Meeting
              </a>
            ) : null}
          </div>
        </>
      );
    case "in-person":
      return (
        <>
          <div className="col-1 my-4">
            <IoMapOutline style={{ fontSize: "1.5em" }} />
          </div>
          <div className="col-11 my-4">
            {meetinglink ? <p className="mb-0">{meetinglink}</p> : null}
          </div>
        </>
      );
    default:
      return null;
  }
};


function Schedule(props) {

  const userInfo = useSelector(state => state.user)

  const [events, setEvents] = useState([]);
  const [eventPopupOpen, setEventPopupOpen] = useState(false);
  const [singleEventData, setSingleEventData] = useState(null);
  const [view, setView] = useState(
    window.innerWidth < 768 ? Views.AGENDA : Views.MONTH
  );
  const [views, setViews] = useState(["month", "agenda"]);

  useEffect( () => {
    getScheduledMeetings();
  }, [])

  useEffect( () => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  const handleResize = () => {
    setView(window.innerWidth < 768 ? Views.AGENDA : Views.MONTH);
    setViews(window.innerWidth < 768 ? ["agenda"] : ["month", "agenda"]);
  }

  const getScheduledMeetings = useCallback(() => {
    axios
      .get(
        `${clientConfig.siteUrl}wl/v1/scheduled_meetings/${userInfo?.userId}`
      )
      .then((response) => {
        if (response.status === 200) {
          let events = [];

          JSON.parse(response.data.scheduled_meetings).map((item, index) => {
            let eventitem = {
              id: index,
              startAt: moment.utc(item.start_date_time).format(),
              endAt: moment.utc(item.end_date_time).format(),
              start: moment.utc(item.start_date_time).format(),
              end: moment.utc(item.end_date_time).format(),
              summary: `${item.start_time} meeting with ${item.user_name}`,
              title: `${item.start_time} meeting with ${item.user_name}`,
              color: "transparent",
              meetinglink: item.meetinglink,
              username: item.user_name,
              userid: item.user_id,
              meetingdate: item.meeting_date,
              duration: item.duration,
              start_time: item.start_time,
              end_time: item.end_time,
              timezoneStartAt: "America/New_York",
              communicationType: item?.communicationType || null,
            };

            events = [...events, eventitem];
          });

          setEvents(events)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userInfo?.userId]);

  const onEventClick = (data) => {
    setEventPopupOpen(!eventPopupOpen);
    setSingleEventData(data);
  };

  const handleClosePopup = () => {
    setEventPopupOpen(false)
  }

  return (
    <MainContainer>
      <div
        className={`schedule-calendar-container py-4${
          eventPopupOpen ? " popup_open" : ""
        }`}
        style={{ height: "calc(100% - 80px)" }}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <h1 className="title mb-2 mb-md-3">Schedule Calendar</h1>
          </div>
          <div className="col-12 col-md-6 text-md-right mb-3 mb-mb-0">
            <Link
              to="/schedule-settings"
              className="btn btn-primary btn-sm rounded-sm px-3"
            >
              <GoGear className="mr-1" size="1.5em" />
              Settings
            </Link>
          </div>
        </div>

        {/* <Kalend
            onEventClick={onEventClick}
            events={events}
            initialDate={new Date().toISOString()}
            hourHeight={60}
            initialView={CalendarView.AGENDA}
            disabledViews={CalendarView[("day", "three days")]}
            disableMobileDropdown={true}
            timeFormat={"12"}
            weekDayStart={"Monday"}
            calendarIDsHidden={["work"]}
            language={"en"}
            style={{
              primaryColor: "indigo",
              baseColor: "#3d3c3c",
              inverseBaseColor: "#f2ecec",
            }}
            timezone="America/New_York"
          /> */}

        <Calendar
          localizer={localizer}
          defaultDate={new Date()}
          defaultView={view}
          views={views}
          events={events}
          style={{ height: "calc(100vh - calc(45px + 2em))" }}
          onSelectEvent={onEventClick}
          popup={true}
          view={view}
          onView={(view) => console.log({ view })}
        />
      </div>

      {/* Modal */}

      <div
        className={`modal fade ${eventPopupOpen ? "show" : ""}`}
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={eventPopupOpen ? { display: "block" } : { display: "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                onClick={handleClosePopup}
                type="button"
                className="close pt-2 pb-0 px-2"
                data-dismiss="modal"
                aria-label="Close"
              >
                <IoCloseOutline />
              </button>
            </div>
            <div className="modal-body pt-0">
              <div className="row">
                <div className="col-1">
                  <span className="badge badge-primary p-2 mt-2">
                    <span className="sr-only">Success</span>
                  </span>
                </div>
                <div className="col-11">
                  <p className="lead mb-0">3For3 Meeting</p>
                  <small className="text-muted d-block">
                    {singleEventData?.meetingdate &&
                      moment(singleEventData?.meetingdate).format(
                        "dddd, MMMM Do YYYY"
                      )}
                  </small>
                  <small className="text-muted d-block">{`${singleEventData?.start_time} - ${singleEventData?.end_time}`}</small>
                </div>

                {/* {singleEventData?.communicationType === "phone" ? (
                  <>
                    <div className="col-1 my-4">
                      <BsTelephone style={{ fontSize: "1.3em" }} />
                    </div>
                    <div className="col-11 my-4">
                      {singleEventData?.meetinglink ? (
                        <a
                          href={`tel:${singleEventData?.meetinglink}`}
                          rel="noreferrer"
                          className="btn btn-primary px-4"
                        >
                          {formatPhoneNumber(singleEventData?.meetinglink)}
                        </a>
                      ) : null}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-1 my-4">
                      <SiJitsi style={{ fontSize: "1.5em" }} />
                    </div>
                    <div className="col-11 my-4">
                      {singleEventData?.meetinglink ? (
                        <a
                          href={singleEventData?.meetinglink}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary px-4"
                        >
                          Join the Meeting
                        </a>
                      ) : null}
                    </div>
                  </>
                )} */}

                <MeetingLinkUI
                  communicationType={singleEventData?.communicationType}
                  meetinglink={singleEventData?.meetinglink}
                />

                <div className="col-1">
                  <FiUsers />
                </div>
                <div className="col-11">
                  <p className="m-0 text-muted">{singleEventData?.username}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}

export default Schedule;
