import Logo from "../../components/logo/logo.components";
import CryptoJS from "crypto-js";
import clientConfig, { secretPass } from "../../env";
import { useQuery } from "../../hooks/useQuery";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";

const styles = {
  callScheduleContainer: {
    minHeight: "100vh",
    backgroundColor: "#212529",
    color: "#ffffff",
  },
};

function UnsubscribeConfirmation() {

  let query = useQuery();
  const [emailAddress, setEmailAddress] = useState("")
  const [feedbackMessage, setFeedbackMessage] = useState("")
  
  useEffect(() => {
      if(query.get("sc")) {
        const queryKey = query.get("sc").trim().replace(/ /g, '+');
        const decryptkey = CryptoJS.AES.decrypt(queryKey, secretPass);
        const data = decryptkey.toString(CryptoJS.enc.Utf8);
        setEmailAddress(data);
      }

  }, [query]);

  const handleClickConfirm = useCallback(async () => {
    try {
      const unsubscribePayload = {
        user_email: emailAddress,
      };

      console.log(unsubscribePayload);

      const headers = {
        "Content-Type": "application/json",
        // Authorization: `Barer ${token}`,
      };

      const response = await axios.post(
        `${clientConfig.siteUrl}wl/v1/preference-status-settings`,
        unsubscribePayload,
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        if (response.data.success) {
          setFeedbackMessage(
            `<span class="text-success">${response.data.success}</span>`
          );
        } else {
          setFeedbackMessage(
            `<span class="text-danger">${response.data.error}</span>`
          );
        }
      } else {
        setFeedbackMessage(
          `<span class="text-danger">${response.data.error}</span>`
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [emailAddress]);


  return (
    <>
      <div style={styles.callScheduleContainer} className="py-3  py-md-5">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="py-4 text-left">
                  <Logo />
                </div>
              </div>
              <div className="col-12 col-md-8 mt-0 mt-md-4">
                <h1 className="text-white mb-3 h2">
                  Unsubscribe from all 3For3 Networking Emails
                </h1>

                <div className="row">
                  <div className="col-md-7">
                    <p className="font-weight-bold h5">{emailAddress}</p>
                    <button
                      type="button"
                      className="btn btn-lg btn-primary px-4 mt-3"
                      onClick={handleClickConfirm}
                      disabled={!emailAddress}
                    >
                      Unsubscribe
                    </button>

                    <p
                      className="mt-3"
                      dangerouslySetInnerHTML={{ __html: feedbackMessage }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UnsubscribeConfirmation;
