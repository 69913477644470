import React, { useState } from 'react';
import './change-password.styles.scss';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';

import clientConfig from '../../env';

//Components
import MainContainer from '../../components/main-container/main-container.component';
import axios from 'axios';
import { generateEncryptKey } from '../../utilities/utilities';

const Input = (props) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    return (
        <div className="form-group">
            <label htmlFor={props.id}>{props.label} <sup className="text-danger">*</sup></label>
            <div className="input-group mb-0">
                <input key={props.id} name={props.id} id={props.id} type={passwordVisible ? "text" : "password"} className="form-control" placeholder={props.placeholder} value={props.value} onChange={ props.onchange } autoComplete="on" />
                <div className="input-group-prepend" onClick={ (e) => {
                    e.preventDefault(); 
                    setPasswordVisible(!passwordVisible);
                }}>
                    <div className="input-group-text">{ passwordVisible ? <AiFillEyeInvisible /> :<AiFillEye />}</div>
                </div>
            </div>
            <small className="text-danger">{props.error}</small>
        </div>
    )
}

const initialState = {
    "oldPassword" : '',
    "newPassword" : '',
    "confirmPassword" : '',
}

function ChangePassword() {

    const [
        { oldPassword, newPassword, confirmPassword },
        setState
    ] = useState(initialState);

    const handleonChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const resetInputFields = () => {
        setState({ ...initialState });
    };

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    
    const token = useSelector(state => state.user.token);
    const userId = useSelector(state => state.user.userId);
    const userEmail = useSelector((state) => state.user.email);

    const validate = () => {
        
        let errors = {};
        let isValid = true;
        let old_password = document.querySelector('#oldPassword').value;
        let new_password = document.querySelector('#newPassword').value;
        let confirm_password = document.querySelector('#confirmPassword').value;

        if (!old_password) {
            isValid = false;
            errors["old_password"] = "Please enter old password.";
        }
        
        if (!new_password) {
            isValid = false;
            errors["new_password"] = "Please enter your new password.";
        }

        if (!confirm_password) {
            isValid = false;
            errors["confirm_password"] = "Please enter confirm password.";
        }
        
        if (typeof new_password !== "undefined" && typeof confirm_password !== "undefined") {
          
            if (new_password !== confirm_password) {
                isValid = false;
                errors["password"] = "Passwords don't match.";
            }
        } 
        
        setErrors(errors);

        return isValid;
    }

    const submitChangePassword = (e) => {
        e.preventDefault();

        if(validate()){
            setLoading(true);
            const data = {
              id: userId,
              old_password: oldPassword,
              new_password: newPassword,
              confirm_password: confirmPassword,
              secretkey: generateEncryptKey(userEmail.toLowerCase()),
            };
    
            const configheader = {
                "Content-Type": "application/json",
                "Authorization": `Barer ${token}`
            }
    
            axios.post(`${clientConfig.siteUrl}wl/v1/change-password`, data, {
                headers: configheader
            }).then( response => {
                if(response.status === 200) {
                    let types = response.data.type;
                    if(types === "success"){
                        setLoading(false);
                        document.querySelector('.change-password-form').reset();
                        resetInputFields();
                        toast.success(response.data.message);
                        return;
                    }
                    toast.error(response.data.message);
                    setLoading(false);
                }
            }).catch( error =>  {
                if(error){
                    setLoading(false);
                    toast.error("Something went wrong");
                }
            });
        }

    }

    return (
      <MainContainer>
        <div className="change-password-container py-4">
          <h1 className="title">Change Password</h1>
          <p>Please enter old password and new password</p>

          <form
            className="change-password-form"
            onSubmit={submitChangePassword}
          >
            <Input
              label="Old Password"
              id="oldPassword"
              value={oldPassword}
              placeholder="Old Password"
              onchange={handleonChange}
              tabIndex="0"
              error={errors && errors.old_password}
            />

            <Input
              label="New Password"
              id="newPassword"
              value={newPassword}
              placeholder="New Password"
              onchange={handleonChange}
              tabIndex="1"
              error={errors && errors.new_password}
            />

            <Input
              label="Confirm Password"
              id="confirmPassword"
              value={confirmPassword}
              placeholder="Confirm Password"
              onchange={handleonChange}
              tabIndex="2"
              error={errors && (errors.confirm_password || errors.password)}
            />

            <button
              type="submit"
              className="btn btn-primary rounded-0 px-4 mt-3"
            >
              {loading ? "Submiting" : "Change Password"}
            </button>

            <div className="form-controls mt-2">
              <Link to="/forgot-password" style={{ color: "#3861b3" }}>
                Forgot Password?
              </Link>
            </div>
          </form>
        </div>
      </MainContainer>
    );
}

export default ChangePassword
