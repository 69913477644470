import React from 'react';
import step4 from '../../images/step-4.webp'

const HowItWorks = ({ savefinalData, email_verified }) => {

    const buttons = (
      <>
        {/* {email_verified === undefined || email_verified === null ? (
        <React.Fragment>
          <button
            className="mt-3 btn btn-primary disabled rounded-0 px-5"
            aria-disabled="true"
            disabled
          >
            Start Now
          </button>
          <p
            className="text-danger mt-2 fs-6"
            style={{ fontSize: "14px" }}
          >
            * Please verify your email to continue.
          </p>
        </React.Fragment>
      ) : (
        <button
          onClick={savefinalData}
          className="mt-3 btn btn-primary rounded-0  px-5"
        >
          Start Now
        </button>
      )} */}
        <button
          onClick={savefinalData}
          className="mt-3 btn btn-primary rounded-0  px-5"
        >
          Start Now
        </button>
      </>
    );

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-6 text-left mt-0 mt-lg-5 mb-3 mb-lg-0">
            <h2
              className="mb-3"
              style={{ fontSize: "clamp(28px, 5vw, 36px)", fontWeight: "700" }}
            >
              Welcome to <br />
              3For3 Networking!
            </h2>

            <p style={{ fontSize: "clamp(15px, 5vw, 18px)" }}>
              We are bringing <b>real people</b> together for{" "}
              <b>real conversations</b>. Our goal is for you to{" "}
              <b>learn something, teach something,</b> and <b>help someone.</b>
            </p>

            <div className="d-none d-lg-block">{buttons}</div>
          </div>

          <div className="col-12 col-lg-6">
            <img src={step4} className="img-fluid" />
            <div className="d-block d-lg-none mt-3 mt-lg-0">{buttons}</div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default HowItWorks;