import React, { useState } from "react";
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { setConnections, setCurrentUser, updateMyCredit } from '../../redux/user/user.actions';
import clientConfig from '../../env';

import UserProfilePopup from '../../components/user-profile-popup/user-profile-popup';
import { useEffect } from "react";

const ScheduleCallBtn = ({ triggerEvent, popupcloseGetData }) => {

    const [popupVisible, setPopupVisible] = useState(false);
    const [profileDataLoaded, setProfileDataLoaded] = useState(true);
    const [profileData, setProfileData] = useState(null);
    const firstname = useSelector((state) => state.user.firstName);
    const userCredit = useSelector((state) => state.user.mycredits);
    const userId = useSelector((state) => state.user.userId);
    const token = useSelector((state) => state.user.token);
    const email_verified = useSelector((state) => state.user.email_verified);
    const mypendingConnection = useSelector(state => state.user.pending_connections);
    const myConnection = useSelector((state) => state.user?.connections || []);
    const dispatch = useDispatch();

    // const connectionArr = [
    //   [...mypendingConnection?.map((item) => +item.id)],
    //   [...myConnection?.map((item) => +item.id)],
    // ]
    //   .flat(1)
    //   .filter((id, i, self) => i === self.indexOf(id));

    console.log(myConnection);
    const connectionArr = [...myConnection?.map((item) => +item.id)]?.filter((id, i, self) => i === self.indexOf(id))
    console.log(myConnection);

    const sendDatatoParent = ({ value }) => {
        setPopupVisible(value);
        popupcloseGetData(value);
    }

    // 3rd Action dependent on 2nd
    const getRecentlyViewedData = async (userId) => {

        try {
            const res = await axios.get(`${clientConfig.siteUrl}wl/v1/get-temp-users/${userId}`);
            const data = res?.data?.result;

            dispatch(setConnections({ recentlyViewed: data }));

        } catch (err) {
            if (err.response) {
                // ✅ log status code here
                console.log(err.response.status);
                console.log(err.message);
                console.log(err.response.headers); // 👉️ {... response headers here}
                console.log(err.response.data); // 👉️ {... response data here}
            }
        }
    }


    // 2nd Action dependent on 1st
    const RandomUserProfile = (id) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Barer ${token}`,
        }
        axios
          .post(
            `${clientConfig.siteUrl}wl/v1/get-random-user`,
            { id: id, connection: JSON.stringify(connectionArr) },
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setProfileDataLoaded(false);
              setProfileData(response.data);
              console.log("############### ",response.data);
              getRecentlyViewedData(userId);
              // let my_pendingConnections = mypendingConnection ? [...mypendingConnection, response.data.id] : [response.data.id]
              // dispatch(setConnections(my_pendingConnections))
            }
          })
          .catch((error) => console.error(error));
    }
    // 1st Action
    const ScheduleCallAction = () => {
        setProfileDataLoaded(true);
        if (userCredit) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Barer ${token}`,
            }

            let updatedCredit = userCredit - 1;

            const data = {
              my_credits: updatedCredit,
            };

            axios.post(`${clientConfig.siteUrl}wl/v1/user/${userId}`, data, {
                headers: headers
            })
                .then(response => {
                    if (response.status === 200) {
                        RandomUserProfile(userId);
                        console.log({ updatedCredit });
                        dispatch(updateMyCredit(updatedCredit));
                    }
                }).catch(error => {
                    console.log(error);
                });

        }

        setPopupVisible(!popupVisible);
    }

    const NextProfileClickToParent = () => {
        setProfileDataLoaded(true);
        ScheduleCallAction();
        setPopupVisible(popupVisible);
    }

    useEffect( () => {
        if (triggerEvent) {
            ScheduleCallAction();
        }
    }, [triggerEvent])

    return(
        <>
            {popupVisible && <UserProfilePopup profile={profileData} popupVisible={popupVisible} sendDatatoParent={sendDatatoParent} NextProfileClickToParent={NextProfileClickToParent} loading={profileDataLoaded} />}
        </>
    )
}

export default ScheduleCallBtn;