import React from 'react';
import './feedback.styles.scss';

//images
import facebookReview from '../../images/write-review-facebook-min.png';
import googleReview from '../../images/review-on-google-min.png';

//icons
import { IconContext } from 'react-icons';
import {
  FaFacebookF,
  FaXTwitter,
  FaInstagram,
  FaGooglePlusG,
  FaRss,
  FaEnvelope,
} from "react-icons/fa";

import { BsTwitterX } from "react-icons/bs";


//components
import MainContainer from '../../components/main-container/main-container.component';
import FeedbackForm from './form/form.component';

const Feedback = () => (
  <MainContainer>
    <div className="feedback-container my-3 my-md-4 px-0 px-md-3">
      <div className="row align-items-center">
        <div className="col-12 col-lg-7">
          <h1 className="title">Feedback</h1>
          <h2 className="subtitle pt-2">
            How is your{" "}
            <span>
              <span>3For3 Networking</span>
            </span>{" "}
            experience?
          </h2>
          <p className="instructions">
            We are always looking for ways to continue to develop the best
            digital networking experience. Please take a moment to leave us
            feedback and recommend us to a friend, family member or business
            collegue.
          </p>

          <FeedbackForm />
        </div>
        <div className="col-12 col-lg-5 text-center">
          <IconContext.Provider value={{ size: "1.5em" }}>
            <ul className="social-channels pb-5">
              <li>
                <a
                  href="https://www.facebook.com/3For3Networking"
                  target="_blank"
                  rel="noreferrer"
                  className="facebook"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/3For3Networking"
                  target="_blank"
                  className="twitter"
                  rel="noreferrer"
                >
                  <BsTwitterX />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/3for3networking/"
                  target="_blank"
                  className="linkedin"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a
                  href="mailto:susan@3for3networking.com"
                  rel="noreferrer"
                  className="mail"
                >
                  <FaEnvelope />
                </a>
              </li>
            </ul>
          </IconContext.Provider>

          <img
            src={facebookReview}
            alt="Facebook Review"
            className="img-fluid mx-auto mb-5 w-75 mt-md-5 mt-lg-0"
          />

          <img
            src={googleReview}
            alt="Google Review"
            className="img-fluid mx-auto mt-3 w-75"
          />
        </div>
      </div>
    </div>
  </MainContainer>
);

export default Feedback;