import React, { useState } from 'react';
import './form.styles.scss';

import clientConfig from '../../../env';
import axios from 'axios';
import { useSelector } from 'react-redux';

import CustomButton from '../../../components/custom-button/custom-button.components'
import { toast } from 'react-toastify';

// const initialState = {
//     rate: "",
//     reason: "",
//     otherReason: "",
//     message: ""
//   };

const FeedbackForm = () => {

    const [rate, setRate] = useState("");
    const [reason, setReason] = useState("");
    const [otherReason, setOtherReason] = useState("");
    const [message, setMessage] = useState("");
    const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

    // const [
    // { rate, reason, otherReason, message },
    // setState
    // ] = useState(initialState);

    const clearState = () => {
        setRate("");
        Array.from(document.querySelectorAll(".form-control")).forEach(
            input => (input.value = "")
        );
    };

    // const onChange = (e, data = null) => {
    //     const { name, value } = e.target;
    //     setState((prevState) => ({ ...prevState, [name]: data ? data : value }));
    // };

    const token = useSelector( (state) => state.user.token );
    const userEmail = useSelector( (state) => state.user.email );
    const userName = useSelector( (state) => `${state.user.firstName} ${state.user.lastName}` );

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabledSubmitBtn(true)
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Barer ${token}`,
        }

        let databody = {
            email: userEmail,
            username: userName,
            message: message,
            rating: rate,
            reason: reason !== "other" ? reason : otherReason
        }

        axios.post(`${clientConfig.siteUrl}wl/v1/send-feedback`, databody, {
            headers: headers
        }).then( response => {
            if(response.status === 200) {
                toast.success(response.data.message);
                clearState();
            } else {
                toast.error(response.data.error);
            }
            setDisabledSubmitBtn(false)
        }).catch( error => {
            console.log(error);
            setDisabledSubmitBtn(false)
        });

    }

    const reasons = reason === "other" ? otherReason : reason;
    const isdisabled =
      (rate && message && reasons && !disabledSubmitBtn) ? false : true; 
    
    return (
      <form className="feedback-form">
        <div className="form-group">
          <label htmlFor="exampleFormControlInput1">
            How likely are you to recommend us to a friend or collegue?
          </label>
          <div
            className="btn-group w-100 rounded-0 feedback-ratings"
            role="group"
            aria-label="rate"
          >
            {Array.from({ length: 10 }, (_, i) => i + 1).map((item) => (
              <button
                key={item}
                onClick={(e) => {
                  e.preventDefault();
                  setRate(item);
                }}
                type="button"
                className={`btn border-primary rounded-circle ${
                  rate === item ? "btn-warning text-white" : "btn-primary"
                }`}
              >
                {item}
              </button>
            ))}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="reasontojoin">
            Why did you join the 3For3 Network?
          </label>
          <select
            onChange={(e) => setReason(e.target.value)}
            defaultValue={reason}
            name="reason"
            className="form-control rounded-0"
          >
            <option value="">Select Option</option>
            <option value="Networking">Networking</option>
            <option value="Mentoring">Mentoring</option>
            <option value="Business">Business</option>
            <option value="Job Search">Job Search</option>
            <option value="Meet New People">Meet New People</option>
            <option value="other">Other</option>
          </select>
          {reason === "other" && (
            <input
              type="text"
              defaultValue={otherReason}
              name="otherReason"
              onChange={(e) => setOtherReason(e.target.value)}
              className="form-control rounded-0 mt-2"
              id="reasontojoin"
              placeholder="Enter reason"
            />
          )}
        </div>

        <div className="form-group">
          <textarea
            onChange={(e) => setMessage(e.target.value)}
            defaultValue={message}
            name="message"
            className="form-control rounded-0 message"
            placeholder="Tell us about your experience..."
          />
        </div>

        <div className="form-group">
          <CustomButton
            onClick={handleSubmit}
            type="submit"
            className={`btn w-100 py-2 ${
              isdisabled ? "btn-outline-primary" : "btn-primary"
            } rounded-0 d-block`}
            disabled={isdisabled}
          >
            Submit
          </CustomButton>
        </div>
      </form>
    );
}

export default FeedbackForm;