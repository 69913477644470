import React, { useState } from 'react'
import './form.styles.scss';

import clientConfig from '../../../env';
import axios from 'axios';
import { useSelector } from 'react-redux';

//components
import CustomButton from '../../../components/custom-button/custom-button.components';
import { toast } from 'react-toastify';

const QuestionForm = () => {

    const [message, setMessage] = useState("");
    const [error, setError] = useState([]);
    const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false)

    const token = useSelector( (state) => state.user.token );
    const userEmail = useSelector( (state) => state.user.email );
    const userName = useSelector( (state) => `${state.user.firstName} ${state.user.lastName}` );

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabledSubmitBtn(true)
        let errormessages = [];
        if(!message) {
            errormessages.push("Please Enter your Question");
            setError(errormessages);
        } else {
            sendAPIRequest();
        }
    }

    const sendAPIRequest = () => {
        const databody = {
            question : message,
            email : userEmail,
            username : userName
        }

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Barer ${token}`,
        }

        axios.post(`${clientConfig.siteUrl}wl/v1/send-faq-question`, databody, {
            headers: headers
        }).then( response => {
            if(response.status === 200) {
                toast.success(response.data.message);
                setMessage("");
                Array.from(document.querySelectorAll(".form-control")).forEach(
                    input => (input.value = "")
                );
            } else {
                toast.error(response.data.error);
            }
            setDisabledSubmitBtn(false);
        }).catch( error => {
            console.log(error);
            setDisabledSubmitBtn(false);
        });
    }

    return (
      <form onSubmit={handleSubmit} className="question-form">
        <textarea
          onChange={(e) => setMessage(e.target.value)}
          className="inputbox form-control"
          placeholder="Ask a Question..."
        ></textarea>
        <CustomButton
          type="submit"
          disabled={message && !disabledSubmitBtn ? false : true}
          className={`btn btn-md rounded-0 ${
            message ? "btn-primary" : "btn-outline-primary"
          } btn-block`}
        >
          Submit
        </CustomButton>
        {error && (
          <ul className="error_list">
            {error.map((msg) => (
              <li>{msg}</li>
            ))}
          </ul>
        )}
      </form>
    );
}

export default QuestionForm;