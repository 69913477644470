import React from 'react';
import './footer.styles.scss';

const Footer = () => (
    <footer className="container-fluid">
        <div className="row">
            <div className="col-12 col-md-6 order-md-12 text-center text-md-right">
                <p className="m-0 text-uppercase">Design &amp; Developed by <span>Janszen Media</span></p>
            </div>
            <div className="col-12 col-md-6 order-md-1 text-center text-md-left">
                <p className="m-0 text-uppercase">&copy; 3For3 Networking</p>
            </div>
        </div>
    </footer>
)

export default Footer;