import React, { Component } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import clientConfig from '../../env';
import { connect, useSelector } from 'react-redux';
import { RiAlarmWarningLine } from 'react-icons/ri';
import { setCurrentUser } from '../../redux/user/user.actions';
import { generateEncryptKey } from '../../utilities/utilities';

function NotificationBar({user}) {

    const resendVerification = () => {

        const httpbody = {
          email: user.email,
          user_id: user.userId,
          username: `${user.firstName} ${user.lastName}`,
          hostURL: window.location.origin,
          secretkey: generateEncryptKey(user.email.toLowerCase()),
        };

        const configheader = {
            "Content-Type": 'application/json',
            "Authorization": `Barer ${user.token}`,
        }

        axios.post(`${clientConfig.siteUrl}wl/v1/resendverify`, httpbody ,{
            headers: configheader
        })
        .then( response =>  {
            if(response.data.status === 200) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        }).catch( error => {
            toast.error(error.response);
        })
    }

    return (
      <div className="alert alert-dismissible  alert-warning d-flex align-items-center justify-content-center mb-0" role="alert">
            <RiAlarmWarningLine className='mr-3' color='#f00' size="1.5em" />
            <div style={{ color: "#212529" }}>Please verify your account by clicking the activation link that has been sent to your email or <div style={{ display: "inline-block", cursor: "pointer" }} className="text-primary" onClick={resendVerification}>resend verification email</div>.</div>
        </div>
    )
}

export default NotificationBar;
