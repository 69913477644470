import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import MainContainer from "../../components/main-container/main-container.component";

import ConnectionsWidget from '../../components/connections/connections.component';
import UserProfilePopup from '../../components/user-profile-popup/user-profile-popup';
import { Link } from 'react-router-dom';

function MyPendingConnections() {

    const mypendingConnection = useSelector(state => state.user.pending_connections);

    const [popupVisible, setPopupVisible] = useState(false);
    const [profileDataLoaded, setProfileDataLoaded] = useState(true);
    const [profileData, setProfileData] = useState(null);

    const getDatapopupfromChild = (data) => {
        setProfileData(data);
        setPopupVisible(!popupVisible);
        setProfileDataLoaded(false);
    }

    const sendDatatoParent = ({ value }) => {
        setPopupVisible(value);
    }

    return (
      <MainContainer>
        <div className="connections-container py-4">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="row">
                <div className="col-sm-6">
                  <h1 className="title mb-4">My Pending Connections</h1>
                </div>
                <div className="col-sm-6 text-md-right">
                  <Link
                    to="/connections"
                    className="btn btn-md btn-outline-secondary px-4 rounded-0 btn-getmorecredit"
                  >
                    Back
                  </Link>
                </div>
              </div>

              <ConnectionsWidget
                getDatapopupfromChild={getDatapopupfromChild}
                connection={
                  Array.isArray(mypendingConnection) ? mypendingConnection : []
                }
                type="popup"
                icontype="arrow"
                pagetype="grid"
              />
            </div>
          </div>

          {popupVisible && (
            <div className="popupConatiner">
              <UserProfilePopup
                profile={profileData}
                popupVisible={popupVisible}
                sendDatatoParent={sendDatatoParent}
                loading={profileDataLoaded}
                nextbtnvisible={false}
              />
            </div>
          )}
        </div>
      </MainContainer>
    );
}

export default MyPendingConnections;