import React from 'react';
import './faq.styles.scss';

//components
import MainContainer from '../../components/main-container/main-container.component';
import Questions from './questions/questions.component';
import QuestionForm from './form/form.component';

const FAQ = () => {
    return (
        <MainContainer>
            <div className="faq-container my-3 my-md-4">
                <div>
                <h1 className="title text-capitalize">Frequently asked questions and answers</h1>
                <p className="subtitle">Still have more questions? Here are some of the most common.</p>
                </div>

                <div className="row">
                    <div className="col-12 col-md-8 align-self-stretch">
                        <Questions />
                    </div>
                    <div className="col-12 col-md-4 align-self-stretch">
                        <QuestionForm />
                    </div>
                </div>
            </div>
        </MainContainer>
    );
}

export default FAQ;