import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../../../redux/user/user.actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import clientConfig from '../../../env';

// Routes
import { Link, useHistory } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const Login = ({ registerVisible, sendDataToParent }) => {

    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    // const [ token, setToken ] = useState();
    // const [ userId, setUserId ] = useState('');
    // const [ userNicName, setUserNicName ] = useState('');
    // const [ userCredit, setUserCredit] = useState();
    const [ loggedIn, setLoggedIn ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState();
    const [btnDisabled, setBtnDisabled] =  useState(true);
    const [showPassword, setShowPassword] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect( () => {
        if (username !== '' && password !== '') {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true);
        }
    }, [username, password] );

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }


    const onLoginFormSubmit = (e) => {
        e.preventDefault();
        const loginData = {
            username: username,
            password: password
        };
        setLoading(true);

        axios.post(`${clientConfig.siteUrl}jwt-auth/v1/token`, loginData)
            .then(response => {
                let data = response.data.data;
                if( undefined === data.token ) {
                    setError(response.data.message);
                    setLoading(false);
                    return;
                }

                let loginInfo = {token: data.token }
                axios.get(`${clientConfig.siteUrl}wl/v1/user/${data.id}`)
                .then( response => {
                    let userInfoData = response.data;
                    let mergedata = {...loginInfo, ...userInfoData};
                    setLoading(false);
                    dispatch(setCurrentUser(mergedata));
                    setLoggedIn(true);
                }).catch( error => {
                    toast.error(error.response.data.message);
                });

            })
            .catch( error => {
                toast.error(error.response);
                console.log(error)
                setUsername('');
                setPassword('');
                if (error?.response?.data.code === "invalid_email") {
                    setError("The email you entered isn't connected to an account. Create an account or try again.");
                } 
                
                if (error?.response?.data.code === "application_passwords_disabled" || error?.response.data.code === "incorrect_password" || error.response.data.statusCode === 403) {
                    const forgotpass = `The password you've entered is incorrect. <a href="/forgot-password">Forgot password?</a>`;
                    setError(forgotpass);
                }
                setLoading(false);
            });

    }

    const isFirstRender = React.useRef(true);

    useEffect( () => {

        if(isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        // localStorage.setItem('token', token);
        // localStorage.setItem('username', userNicName);
        // localStorage.setItem('userid', userId);
        // localStorage.setItem('usercredit', userCredit);

        if(loggedIn) {
            history.push("/dashboard")
        }

    },[loggedIn, history]);

    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {error && (
          <div
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        )}
        <div
          className="login card py-3 px-2"
          style={{ backgroundColor: "transparent", border: "0" }}
        >
          <div className="card-body">
            <form onSubmit={onLoginFormSubmit}>
              <input
                name="username"
                type="text"
                required={true}
                className="form-control form-control-lg mb-3"
                placeholder="Enter your email"
                autoComplete="off"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value?.trim().toLowerCase());
                  // checkNotEmpty(e);
                }}
              />

              <div className="form-group">
                <div className="input-group">
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required={true}
                    className="form-control form-control-lg"
                    placeholder="Enter your password"
                    autoComplete="off"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value?.trim());
                      // checkNotEmpty(e);
                    }}
                  />
                  <div
                    className="input-group-prepend"
                    onClick={handleShowPassword}
                  >
                    <div
                      className="border-left-0 input-group-text"
                      style={{ borderRadius: "0 3px 3px 0", cursor: "pointer" }}
                    >
                      {!showPassword ? <FiEyeOff /> : <FiEye />}
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-primary btn-lg btn-block"
                disabled={btnDisabled}
              >
                {loading ? "Loading..." : "Login"}
              </button>
            </form>
            <div className="text-center my-4">
              <Link to="/forgot-password" className="forgot-password-link">
                Forgot password?
              </Link>
            </div>

            <div className="text-center pt-3">
              <button
                className="btn btn-success btn-lg"
                onClick={() => sendDataToParent(!registerVisible)}
              >
                Create a new account
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default Login;