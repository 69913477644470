import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setCurrentUser } from '../../redux/user/user.actions';
import "./connections.styles.scss";
import axios from 'axios';
import clientConfig from '../../env';

//Components
import MainContainer from "../../components/main-container/main-container.component";
import ConnectionsWidget from '../../components/connections/connections.component';
import CustomButton from '../../components/custom-button/custom-button.components';
import UserProfilePopup from "../../components/user-profile-popup/user-profile-popup";

const Connections = () => {

  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.userId);
  const token = useSelector(state => state.user.token);
  const myCredits = useSelector(state => state.user.mycredits);
  const myConnection = useSelector(state => state.user.connections);
  const mypendingConnection = useSelector(state => state.user.pending_connections);
  const recentlyViewedConnection = useSelector(state => state.user.recentlyViewed, shallowEqual);

  const [popupVisible, setPopupVisible] = useState(false);
  const [profileDataLoaded, setProfileDataLoaded] = useState(true);
  const [profileData, setProfileData] = useState(null);

  const [recentViewed, setRecentViewed] = useState();

  const getRecentlyViewedData = async () => {
    if (!recentViewed?.length) {
      try{
        const res = await axios.get(`${clientConfig.siteUrl}wl/v1/get-temp-users/${userId}`);
        const data = res?.data?.result;
        setRecentViewed(data)
      } catch (err) {
        if (err.response) {
          // ✅ log status code here
          console.log(err.response.status);
          console.log(err.message);
          console.log(err.response.headers); // 👉️ {... response headers here}
          console.log(err.response.data); // 👉️ {... response data here}
        }
      }
    }
  }

  const getDatapopupfromChild = (data) => {
    setProfileData(data);
    setPopupVisible(!popupVisible);
    setProfileDataLoaded(false);
  }

  const sendDatatoParent = ({ value }) => {
    setPopupVisible(value);
  }

  const getLatestData = () => {
    axios.get(`${clientConfig.siteUrl}wl/v1/user/${userId}`)
      .then(res => {
        let loginInfo = { token: token }
        dispatch(setCurrentUser({ ...loginInfo, ...res.data }));
      }).catch(err => console.error(err));
  }

  useEffect( () => {
    getRecentlyViewedData();
    getLatestData();
  }, []);

  useEffect( () => {
    if (recentlyViewedConnection?.length) {
      setRecentViewed(recentlyViewedConnection)
    }
  }, [recentlyViewedConnection])

  const getLastfiveData = (arr) => {
    console.log({ arr });
    return arr?.length ? arr?.slice(Math.max(arr.length - 5, 0)).reverse() : arr
  }

  console.log({ mypendingConnection });

  return (
    <MainContainer>
      <div className="connections-container py-4">
        <div className="row">
          <div className="col-12 col-sm-4">
            <h1 className="title mb-4">Connections</h1>
          </div>
          <div className="col-12 col-sm-8 text-sm-right mb-4">
            <div className="align-items-baseline align-items-center d-flex flex-column flex-sm-row">
              <span className="credit-remaining-text mb-0 mb-md-0 ml-sm-auto">
                {myCredits} Credits Remaining
              </span>
              <CustomButton
                data-toggle="modal"
                data-target="#personalInviteModal"
                className="btn btn-md btn-outline-secondary px-4 rounded-0 btn-getmorecredit ml-0 ml-sm-4 mt-2 mt-sm-0"
              >
                Get More Credits
              </CustomButton>
            </div>
          </div>

          <div className="col-12 col-md-4 col-lg-4 mb-4 mb-md-0">
            <ConnectionsWidget
              connection={getLastfiveData(myConnection)}
              widgetTitle="My Connections"
              type="link"
              morelink="/my-connections"
              limit="5"
              icontype="person"
              userId={userId}
            />
          </div>

          <div className="col-12 col-md-4 col-lg-4 mb-4 mb-md-0">
            <ConnectionsWidget
              getDatapopupfromChild={getDatapopupfromChild}
              connection={getLastfiveData(mypendingConnection)}
              widgetTitle="Request Pending"
              type="popup"
              morelink="/pending-connections"
              limit="5"
              icontype="arrow"
              userId={userId}
            />
          </div>

          {/* <div className="col-12 col-md-4 col-lg-4">
            <ConnectionsWidget getDatapopupfromChild={getDatapopupfromChild} connection={getLastfiveData(recentlyViewedConnection?.length ? recentlyViewedConnection : recentViewed)} widgetTitle="Recently Viewed" type="popup" limit="5" icontype="arrow" />
        </div> */}
          <div className="col-12 col-md-4 col-lg-4">
            <ConnectionsWidget
              getDatapopupfromChild={getDatapopupfromChild}
              connection={getLastfiveData(recentViewed)}
              widgetTitle="Recently Viewed"
              type="popup"
              limit="5"
              icontype="arrow"
              userId={userId}
            />
          </div>
        </div>

        {popupVisible && (
          <div className="popupConatiner">
            {/* <UserProfilePopup profile={profileData} popupVisible={popupVisible} nextbtnvisible={false} sendDatatoParent={sendDatatoParent}/> */}

            <UserProfilePopup
              profile={profileData}
              popupVisible={popupVisible}
              sendDatatoParent={sendDatatoParent}
              loading={profileDataLoaded}
              nextbtnvisible={false}
            />
          </div>
        )}
      </div>
    </MainContainer>
  );
}

export default Connections;
