import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './forgot-password.style.scss';

import { Link, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GrUserAdmin } from 'react-icons/gr';
import 'react-toastify/dist/ReactToastify.css';

import clientConfig from '../../env';
import Logo from '../../components/logo/logo.components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const ForgotPassword = () => {

    const history = useHistory();
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [password, setPassword] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [submitDisable, setSubmitDisable] = useState(true);
    
    const search = useLocation().search;
    const params = new URLSearchParams(search);
    const query_key = params.get('key');
    const query_user = params.get('user');
    useEffect(() => {
        if(params.has('key') && params.has('user')) {
            setVerificationCode(query_key.toString());
            setEmail(query_user);
            console.log(search);
        }
        
    },
    // eslint-disable-next-line
    []);

    const onchangeEmailValidate = (e) => {

        setEmail(e.target.value.trim());
        let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if ( filter.test(e.target.value) ) {
            setSubmitDisable(false);
        } else {
            setSubmitDisable(true);
        }
    }

    const handleSearch = (e) => {
        setSubmitDisable(true);
        e.preventDefault();
        const data = {
            "email": email,
        };
        axios.post(`${clientConfig.siteUrl}wl/v1/reset-password`, data)
        .then( response => {
        
            let result = response.data
            if(result.data.status === 200) {
                // alert(result.message); 
                toast.success(result.message);
                setSuccessMsg(result.message);
            } else {
                setErrorMsg(result.message);
            }
            setSubmitDisable(false);
        })
        .catch( error => {
            setErrorMsg(error.response.data.message);
            setSubmitDisable(false);
        });
    }

    const handleSetpassword = async (e) => {
        e.preventDefault();
        //Code verification
        const verifiedData = {
            "email": email,
            "code": verificationCode
        }

        try{
            const verification_res = await axios.post(`${clientConfig.siteUrl}wl/v1/validate-code`, verifiedData);
    
            if(verification_res.data.data.status === 200) {
                const passwordData = {
                    "email": email,
                    "code": verificationCode,
                    "password": password
                }
                const password_res = await axios.post(`${clientConfig.siteUrl}wl/v1/set-password`, passwordData);
    
                let result = password_res.data;
                if(result.data.status === 200) {
                    // alert('Password rest successful');
                    toast.success(result.message);
                    setSuccessMsg(result.message);
                    setSubmitDisable(false);
                    history.push("/")
                } else {
                    setErrorMsg(result.message);
                    setSubmitDisable(false);
                }
            } else {
                setErrorMsg(verification_res.data.data.message);
                setSubmitDisable(false);
            }
        } catch( err ) {
            console.error('error', err.response);
            toast.error(err.response);
            setErrorMsg(err.response.data.message);
            setSubmitDisable(false);
        }
    }

    return(
        <main className="container-fluid forgot-password-container">
            <nav className="navbar fixed-top navbar-light">
                <Link to="/" className="navbar-brand" style={{ fontSize: '22px', color: "#ffffff" }}>
                    <Logo size="small" />
                </Link>
                
                <Link to="/" className="btn btn-primary btn-login  my-2 my-sm-0 px-4" type="submit"><GrUserAdmin size="1.2em" style={{ marginRight: "5px" }}  /> Login</Link>
            </nav>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <article className="container h-100">
                <div className="row h-100 align-items-center">
                    <div className="col-md-6 offset-md-3">
                        <div className="card">
                            <div className="card-header text-center pb-0">
                                <h3 className="mb-0">Password Reset</h3>
                            </div>
                            <div className="card-body">
                                { ( params.has('key') && params.has('user') ) ? <p>Please enter a new password to procced.</p> : <p>Please enter your email address to reset your password.</p> }
                                
                                <form >
                                    <div className="form-group">
                                        { ( params.has('key') && params.has('user') ) ? 
                                        <input type="email" className="form-control disabled" onChange={onchangeEmailValidate} placeholder="Email" readOnly value={email} />
                                        :
                                        <input type="email" className="form-control" onChange={onchangeEmailValidate} placeholder="Please enter your email" value={email} />
                                        }
                                    </div>
                                    { ( params.has('key') && params.has('user') ) && 
                                    <React.Fragment>
                                    <div className="form-group">
                                        <input type="password" className="form-control" onChange={ (e) => setPassword(e.target.value) }  placeholder="Enter your new password" value={password} />
                                    </div>
                                    </React.Fragment>
                                    }
                                </form>
                            </div>
                            <div className="card-footer">
                                <div className="text-center">
                                    <Link to="/" className="btn btn-fp  btn-light mr-2">Cancel</Link>
                                    { ( params.has('key') && params.has('user') ) ? 
                                        <button type="button" onClick={handleSetpassword} className="btn btn-primary btn-fp ml-2 px-4">Set Password</button>
                                    :
                                        <button type="button" onClick={handleSearch} className="btn btn-primary btn-fp ml-2 px-4" disabled={submitDisable}>Submit</button>
                                    }
                                </div>
                            </div>
                        </div>

                        {successMsg &&
                            <div className="alert alert-success" role="alert">
                                {successMsg}
                            </div>
                        }

                        {errorMsg &&
                            <div className="alert alert-danger" role="alert">
                                {errorMsg}
                            </div>
                        }


                    </div>

                    
                    
                </div>
            </article>
        </main>
    );
}
export default ForgotPassword;