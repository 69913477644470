import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../redux/user/user.actions';
import clientConfig from '../env';

const useLatestUserData = () => {
  const dispatch = useDispatch();

  const fetchLatestUserData = async ({ id, token }) => {
    try {
      const response = await axios.get(`${clientConfig.siteUrl}wl/v1/user/${id}`);
      dispatch(setCurrentUser({ token, ...response.data }))
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  // Usage: fetchLatestUserData({ id, token });

  // Return the function so it can be called externally
  return fetchLatestUserData;

};

export default useLatestUserData;