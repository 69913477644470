import React, { useCallback, useEffect, useState } from "react";
import Logo from "../../components/logo/logo.components";
import axios from "axios";
import clientConfig, { secretPass } from "../../env"
import CryptoJS from "crypto-js";
import { generateEncryptKey } from "../../utilities/utilities";

const styles = {
  callScheduleContainer: {
    minHeight: "100vh",
    backgroundColor: "#212529",
    color: "#ffffff",
  },
};

const Unsubscribe = () => {

    const [inputError, setInputError] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const handleChangeInput = (e) => {
        setInputValue(e.target.value);
    }

    const handleClickConfirm = (e) => {
        e.preventDefault();
        const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        if (inputValue === "") {
            setInputError("Please enter Username / Email");
        } else if (!emailRegex.test(inputValue)) {
            setInputError("Please enter valid email");
        } else {
            setInputError(null);
            handleSendUnsubscribeEmail(inputValue);
        }
    }

    const handleSendUnsubscribeEmail = useCallback(
      async (emailAddress) => {
        try {
          const unsubscribePayload = {
            user_email: emailAddress.toLowerCase(),
            appHost: window.location.origin,
            secretkey: generateEncryptKey(emailAddress.toLowerCase()),
          };

          const headers = {
            "Content-Type": "application/json",
            // Authorization: `Barer ${token}`,
          };

          const response = await axios.post(
            `${clientConfig.siteUrl}wl/v1/send-unsubscribe-email`,
            unsubscribePayload,
            {
              headers: headers,
            }
          );

          if (response?.status === 200) {
            response.data.email_sent
              ? setSuccessMsg(
                  `<span class="text-success">${response.data.success}</span>`
                )
              : setSuccessMsg(
                  `<span class="text-danger">${response.data.error}</span>`
                );
          } else {
            setSuccessMsg(
              `<span class="text-danger">${response.data.error}</span>`
            );
          }

          if (response.data.email_sent) {
            let timer;
            clearTimeout(timer);

            timer = setTimeout(() => {
              setSuccessMsg("");
              setInputValue("");
            }, 3000);
          }
        } catch (error) {
          console.log(error);
        }
      },
      [inputValue]
    );

    return (
      <>
        <div style={styles.callScheduleContainer} className="py-3  py-md-5">
          <div className="container-fluid">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="py-4 text-left">
                    <Logo />
                  </div>
                </div>
                <div className="col-12 col-md-8 mt-0 mt-md-4">
                  <h1 className="text-white mb-3 h2">
                    Unsubscribe from all 3For3 Networking Emails
                  </h1>
                  <p className="text-white">
                    Enter your email and click confirm to unsubscribe from all
                    emails, including invitations from other 3For3 Networking
                    users.
                  </p>

                  <div className="row">
                    <div className="col-md-7">
                      <input
                        type="email"
                        placeholder="Enter Email to Unsubscribe"
                        className="form-control form-control-lg"
                        onChange={handleChangeInput}
                        value={inputValue}
                      />
                      <small className="form-text text-danger">
                        {inputError}
                      </small>
                      <button
                        type="button"
                        className="btn btn-lg btn-primary px-4 mt-3"
                        onClick={handleClickConfirm}
                        disabled={inputValue !== "" ? false : true}
                      >
                        Confirm
                      </button>
                      {successMsg ? (
                        <p
                          className="mt-3"
                          dangerouslySetInnerHTML={{ __html: successMsg }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Unsubscribe;