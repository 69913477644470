import React, { useCallback, useEffect, useRef } from 'react';
import './header.styles.scss';

//Icons
import { FiCalendar } from 'react-icons/fi';
import { HiOutlineMenu } from "react-icons/hi";

//components
import CustomButton from '../custom-button/custom-button.components';
import UserDropdown from '../user-dropdown/user-dropdown.component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import StepsForm from '../stepsform/stepsform.components';
import NotificationBar from '../notificationBar/notificationBar.components';
import clientConfig from '../../env';
import axios from 'axios';
import { setCurrentUser, setMyCredit } from '../../redux/user/user.actions';
import Logo from '../logo/logo.components';

const Header = ({ parentFunction, sidebarMenuOpenParent }) => {
  const getDetailsRenderRef = useRef(true)
  const history = useHistory();
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.user.status);
  const email_verified = useSelector((state) => state.user.email_verified);
  const user_details = useSelector((state) => state.user);
  const userCredit = useSelector((state) => state.user.mycredits);
  const userId = useSelector((state) => state.user.userId);
  const stepsFormComplete = useSelector(
    (state) => state.user.steps_form_complete
  );

  console.log("userCredit", userCredit);
  console.log("userId", userId);

  const dispatch = useDispatch();

  if (!token) {
    history.push("/");
    // const params = new URL(window.location.href).searchParams;
    // if (params.get("request") && params.get("context")) {
    //   console.log("I am here", params.get("request"), params.get("context"));
    // }
  }

  const getUserDetails = useCallback(() => {
    console.log("getUserDetails function");
    axios
      .get(`${clientConfig.siteUrl}wl/v1/user/${userId}`)
      .then((res) => {
        if (res?.data) {
          dispatch(setCurrentUser({ ...user_details, ...res.data }));
          dispatch(setMyCredit(res?.data?.my_credits));
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  useEffect(() => {
    if(getDetailsRenderRef.current) {
      getDetailsRenderRef.current = false
      getUserDetails();
      return
    }
  }, []);

  return (
    <React.Fragment>
      {!email_verified && <NotificationBar user={user_details} />}
      {(stepsFormComplete !== "1" || !stepsFormComplete) && <StepsForm />}

      <header>
        <nav className="navbar sticky-top flex-md-nowrap px-0 py-1 shadow">
          <a
            className="navbar-brand col-5 col-sm-4 col-md-3 col-lg-2 pl-1 pr-0 px-sm-3 mr-0"
            href="/"
          >
            <Logo size="extraSmall" />
          </a>

          <div className="right-menu col-7 col-sm-8 col-md-9 col-lg-10 px-0">
            <div className="row no-gutters align-items-center justify-content-end">
              {/* <div className="col-md-5 d-none d-md-block search-input-container">
                            
                            <input type="search" className="form-control rounded-0 search-input" placeholder="Search the 3 for 3 Network" />

                        </div> */}
              <div className="col-12 col-lg-7">
                <div
                  className="row no-gutters justify-content-end align-items-center"
                  style={{ gap: 10 }}
                >
                  {/* <div className="col text-center d-block d-md-none">
                                    <FiSearch  className="search-icon"/>
                                </div> */}

                  {/* <div className="col col-lg-4 get_credit_btn">
                                     <a href="javascript.void(0)"
                                        aria-label="Add a new report"
                                        data-toggle="modal" data-target="#exampleModal">
                                        <FiGift color="#ffffff" size="1.2em" />
                                        <span>Get 20 Credits</span>
                                    </a>
                                </div> */}

                  <CustomButton
                    onClick={parentFunction}
                    type="button"
                    otherClass={`col col-lg-4 btn btn-primary rounded-0 px-2 px-md-4 font-weight-light btn-schedule ${
                      (!!!userCredit || email_verified === null) && "disabled"
                    }`}
                    disabled={
                      !!!userCredit || email_verified == null
                        ? true
                        : false || status === "Inactive"
                    }
                  >
                    <FiCalendar color="#ffffff" /> <span>Schedule a Call</span>
                  </CustomButton>

                  <div className="col-xs-2 d-block d-md-none text-center">
                    <button
                      className="navbar-toggler p-0 d-md-none collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#sidebarMenu"
                      aria-controls="sidebarMenu"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      onClick={(e) => {
                        sidebarMenuOpenParent(true);
                        e.preventDefault();
                        let htmlbody = document.body;
                        htmlbody.style.overflow = "auto";
                        htmlbody.style.overflowX = "auto";
                        htmlbody.style.overflowY =
                          htmlbody.style.overflowY === "hidden"
                            ? "auto"
                            : "hidden";
                      }}
                    >
                      <HiOutlineMenu color="#ffffff" />
                      <span>Menu</span>
                    </button>
                  </div>

                  <UserDropdown />
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div className="mobile-search-input-container p-2">
          <input
            type="search"
            className="form-control rounded-0 search-input"
            placeholder="Search the 3 for 3 Network"
          />
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;