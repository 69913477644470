import React, { useEffect, useRef, useState } from "react";
import "../my-profile/my-profile.styles.scss";

import { IconContext } from "react-icons";
import { MdEmail, MdHomeWork } from "react-icons/md";
import { BsTelephoneFill, BsStars, BsBriefcase } from "react-icons/bs";
import { FaBirthdayCake } from "react-icons/fa";
import { ImHappy2 } from "react-icons/im";
import { IoSchoolOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { setCurrentUser } from "../../redux/user/user.actions";
import { toast } from "react-toastify";
import { uuid } from 'uuidv4';

//components
import MainContainer from "../../components/main-container/main-container.component";
import ConnectionsWidget from "../../components/connections/connections.component";
import UserProfilePopup from "../../components/user-profile-popup/user-profile-popup";

import axios from "axios";
import clientConfig from "../../env";

import { formatPhoneNumber } from "../../utilities/utilities";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import moment from "moment";

const initailConnectionInfo = {
  connectWithId: null,
  headline: null,
  birthday: null,
  city: null,
  state: null,
  country: null,
  description: null,
  email: null,
  phone: null,
  first_name: null,
  last_name: null,
  gender: null,
  industry: null,
  interested_in: null,
  classification: null,
  organization: null,
  organization_title: null,
  profile_banner: null,
  profile_picture: null,
  university: null,
  university_degree: null,
  zip: null,
  url: null,
  popupVisible: false,
  profileData: null,
};

// function MyConnection(props) {

//   const scheduleCallRef = useRef();

//   const [connectionInfo, setConnectionInfo] = useState(initailConnectionInfo);
//   const [popupVisible, setPopupVisible] = useState(false)

//   useEffect( () => {
//     getUserData(props.match.params.id);

//     const checkIfClickedOutside = (e) => {
//       // If the menu is open and the clicked target is not within the menu,
//       // then close the menu
//       if (
//         this.state.popupVisible &&
//         this.scheduleCallRef.current &&
//         !this.scheduleCallRef.current.contains(e.target)
//       ) {
//         setPopupVisible(false)
//         this.sendDatatoParent(false);
//       }
//     };

//     document.addEventListener("mousedown", checkIfClickedOutside);

//     return () => {
//       // Cleanup the event listener
//       document.removeEventListener("mousedown", checkIfClickedOutside);
//     };
//   }, [])

//   // get the connection profile information
//   const getUserData = async (profile_key) => {
//     try {
//       const response = await axios.get(
//         `${clientConfig.siteUrl}wl/v1/user_by_key/${profile_key}`
//       );

//       if (response.status === 200 && response.data) {
//         const {
//           headline,
//           city,
//           state,
//           country,
//           description,
//           email,
//           first_name,
//           last_name,
//           gender,
//           industry,
//           interested_in,
//           organization,
//           organization_title,
//           phone,
//           profile_banner,
//           profile_picture,
//           university,
//           university_degree,
//           zip,
//           url,
//           classification,
//           birthday,
//           id,
//         } = response.data;

//         setConnectionInfo({
//           connectWithId: id,
//           headline,
//           birthday,
//           city,
//           state,
//           country,
//           description,
//           email,
//           phone,
//           first_name,
//           last_name,
//           gender,
//           industry,
//           interested_in,
//           classification,
//           organization,
//           organization_title,
//           profile_banner,
//           profile_picture,
//           university,
//           university_degree,
//           zip,
//           url,
//           profileData: response.data,
//         });
//       }
//     } catch (error) {
//       console.error(error);
//     }

//   }

//   const location = (city, state, country, zip) => {
//     let address = "";
//     if (city) address += city;
//     if (state) address += `, ${state}`;
//     if (zip) address += `  ${zip}`;
//     if (country) address += ` - ${country}`;
//     return address;
//   };

//   const CompanyPosition = (title, organization) => {
//     let designation = title ? title : "";
//     let company = organization ? ` at ${organization}` : "";
//     return `${designation}${company}`;
//   };

//   const education = (degree, school) => {
//     let edu_degree = degree ? degree : "";
//     let edu_school = school ? ` at ${school}` : "";
//     return `${edu_degree}${edu_school}`;
//   };

//   const sendDatatoParent = (value) => {
//     setPopupVisible(value);
//   };

//   const handleremoveFromConnection = async (userId, connectwith_Id) => {

//     try {
//       const httpbody = {
//         userId: userId,
//         connectwithUserId: connectwith_Id,
//       };

//       const configheader = {
//         "Content-Type": "application/json",
//         Authorization: `Barer ${this.props.userInfo.token}`,
//       };

//       const response = await axios.post(
//         `${clientConfig.siteUrl}wl/v1/remove-from-myconnection`,
//         httpbody,
//         {
//           headers: configheader,
//         }
//       );

//       if (response) {
//         document.getElementById("close_confirmation_window").click();
//         props.history.push("/");
//         toast.success("User Removed Successfully");
//       }
//     } catch(error) {
//       toast.error("Something went wrong, please try again after some time");
//     }

//   };

//   const { userInfo } = props;
//   const locationInfo = location(
//     city,
//     state,
//     country,
//     zip
//   );

//   return (
//     <>
//       <MainContainer>
//         {popupVisible ? (
//           <>
//             <h2 className="text-center w-100 mb-1 mt-4">
//               {userInfo?.firstName}, Let's Meet
//             </h2>
//             <UserProfilePopup
//               profile={profileData}
//               popupVisible={popupVisible}
//               sendDatatoParent={sendDatatoParent}
//               nextbtnvisible={false}
//               titleVisible={false}
//               showCancelButton={true}
//               requestFromMyConnection={true}
//             />
//           </>
//         ) : null}
//         <div className="my-profile-container px-3 my-4">
//           <div className="row my-4">
//             <div className="col-12 col-lg-8">
//               <div className="my-profile-box">
//                 <div className="row">
//                   <div className="w-100">
//                     <div
//                       className="profile-banner"
//                       style={{
//                         backgroundImage: `url(${
//                           connectionInfo?.profile_banner &&
//                           connectionInfo?.profile_banner
//                         })`,
//                       }}
//                     >
//                       <div
//                         className="profile-image"
//                         style={{
//                           backgroundImage: `url(${
//                             connectionInfo?.profile_picture &&
//                             connectionInfo?.profile_picture
//                           })`,
//                         }}
//                       ></div>
//                     </div>
//                   </div>

//                   <div className="w-100 text-right mt-n5 pt-2">
//                     <p
//                       className="editPopup"
//                       data-toggle="modal"
//                       data-target="#confirmationRemoveConnection"
//                     >
//                       <span className="text-danger d-flex align-items-center">
//                         <RiDeleteBin6Line className="text-danger mr-1" /> Remove
//                         Connection
//                       </span>
//                     </p>
//                     <button
//                       onClick={() => setPopupVisible(!popupVisible)}
//                       className="btn btn-primary rounded-0 px-4 btn-schedule-call mx-3"
//                     >
//                       Schedule a Call
//                     </button>
//                   </div>

//                   <div className="col-12 col-md-6 col-lg-6 col-xl-7">
//                     <h3 className="name h4 mb-1">
//                       {connectionInfo.first_name || connectionInfo.last_name ? (
//                         `${connectionInfo?.first_name} ${connectionInfo?.last_name}`
//                       ) : (
//                         <span className="placeholder_text">Full Name</span>
//                       )}
//                     </h3>

//                     <p className="headline mb-0">
//                       {connectionInfo?.headline ? (
//                         connectionInfo?.headline
//                       ) : (
//                         <span className="placeholder_text">Headline</span>
//                       )}
//                     </p>

//                     <p className="location mb-0">
//                       {locationInfo ? (
//                         locationInfo
//                       ) : (
//                         <span className="placeholder_text">Location</span>
//                       )}
//                     </p>

//                     <p className="location text-truncate mb-0">
//                       {connectionInfo?.url ? (
//                         <a
//                           style={{ color: "#3861b3" }}
//                           href={connectionInfo?.url}
//                           target="_blank"
//                           rel="noreferrer"
//                         >
//                           {connectionInfo?.url}
//                         </a>
//                       ) : null}
//                     </p>
//                   </div>
//                   <div className="col-12 col-md-6 col-lg-6 col-xl-5">
//                     <ul className="workex_school">
//                       <li>
//                         {connectionInfo?.organization_thumb ? (
//                           <div
//                             className="thumb"
//                             style={{
//                               backgroundImage: `url(${connectionInfo?.organization_thumb})`,
//                             }}
//                           />
//                         ) : (
//                           <div className="thumb">
//                             <BsBriefcase size="1.3em" color="#a7a7a7" />
//                           </div>
//                         )}
//                         {connectionInfo?.organization_title &&
//                         connectionInfo?.organization ? (
//                           CompanyPosition(
//                             connectionInfo?.organization_title,
//                             connectionInfo?.organization
//                           )
//                         ) : (
//                           <span className="placeholder_text">
//                             Current Position at Company Name
//                           </span>
//                         )}
//                       </li>
//                       <li>
//                         <div className="thumb">
//                           <IoSchoolOutline size="1.6em" color="#a7a7a7" />
//                         </div>
//                         {connectionInfo?.university &&
//                         connectionInfo?.university_degree ? (
//                           education(
//                             connectionInfo?.university_degree,
//                             connectionInfo?.university
//                           )
//                         ) : (
//                           <span className="placeholder_text">
//                             Degree at School Name
//                           </span>
//                         )}
//                       </li>
//                     </ul>
//                   </div>

//                   <hr className="w-100 mb-4" />
//                   <IconContext.Provider
//                     value={{
//                       color: "#333333",
//                       size: "1.3em",
//                       className: "profile_field_icons",
//                     }}
//                   >
//                     <div className="col-12 col-md-6">
//                       <p>
//                         <MdEmail />{" "}
//                         {connectionInfo?.email ? (
//                           connectionInfo?.email
//                         ) : (
//                           <span className="placeholder_text">Your Email</span>
//                         )}
//                       </p>
//                     </div>

//                     <div className="col-12 col-md-6">
//                       <p>
//                         <BsTelephoneFill />{" "}
//                         {connectionInfo?.phone ? (
//                           formatPhoneNumber(connectionInfo?.phone)
//                         ) : (
//                           <span className="placeholder_text">
//                             Phone / Mobile Number
//                           </span>
//                         )}
//                       </p>
//                     </div>

//                     <div className="col-12 col-md-6">
//                       <p>
//                         <FaBirthdayCake />{" "}
//                         {connectionInfo?.birthday ? (
//                           connectionInfo?.birthday.split("-").join("/")
//                         ) : (
//                           <span className="placeholder_text">
//                             Your Birthdate
//                           </span>
//                         )}
//                       </p>
//                     </div>

//                     <div className="col-12 col-md-6">
//                       <p>
//                         <ImHappy2 />{" "}
//                         {connectionInfo?.interested_in ? (
//                           connectionInfo?.interested_in
//                         ) : (
//                           <span className="placeholder_text">
//                             Your Interest
//                           </span>
//                         )}
//                       </p>
//                     </div>

//                     <div className="col-12">
//                       <div className="row">
//                         <div className="col-12 col-md-6">
//                           <p>
//                             <MdHomeWork />{" "}
//                             {connectionInfo?.industry ? (
//                               connectionInfo?.industry
//                             ) : (
//                               <span className="placeholder_text">Industy</span>
//                             )}
//                           </p>
//                         </div>
//                         <div className="col-12 col-md-6">
//                           <p>
//                             <BsStars />{" "}
//                             {connectionInfo?.classification ? (
//                               connectionInfo?.classification
//                             ) : (
//                               <span className="placeholder_text">
//                                 Classifications
//                               </span>
//                             )}
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </IconContext.Provider>
//                 </div>
//               </div>

//               <div className="my-profile-about-box">
//                 <div className="row">
//                   <div className="col-12">
//                     <h3 className="h4">About</h3>

//                     <p className="m-0">
//                       {connectionInfo?.description ? (
//                         connectionInfo?.description
//                       ) : (
//                         <span className="placeholder_text text-mute">
//                           Write about your years of experience, industry, or
//                           skills. People also talk about their achievements or
//                           previous job experiences.
//                         </span>
//                       )}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="col-12 col-lg-4">
//               <ConnectionsWidget
//                 connection={userInfo.connections}
//                 widgetTitle="My Connections"
//                 limit="5"
//                 type="link"
//                 morelink="/connections"
//                 icontype="person"
//               />
//             </div>
//           </div>

//           <div
//             className="modal fade"
//             id="confirmationRemoveConnection"
//             data-backdrop="static"
//             data-keyboard="false"
//             tabIndex="-1"
//             aria-labelledby="confirmation_modal"
//             aria-hidden="true"
//           >
//             <div className="modal-dialog modal-dialog-centered">
//               <div className="modal-content">
//                 <div className="modal-header">
//                   <h5 className="modal-title" id="confirmation_modal">
//                     Confirmation
//                   </h5>
//                   <button
//                     type="button"
//                     className="close"
//                     data-dismiss="modal"
//                     aria-label="Close"
//                   >
//                     <span aria-hidden="true">&times;</span>
//                   </button>
//                 </div>
//                 <div className="modal-body">
//                   <p className="m-0">
//                     Are you sure you would like to remove{" "}
//                     <b>{`${connectionInfo?.first_name} ${connectionInfo?.last_name}`}</b>{" "}
//                     from your connections list?
//                   </p>
//                 </div>
//                 <div className="modal-footer">
//                   <button
//                     type="button"
//                     className="btn btn-danger"
//                     onClick={() =>
//                       handleremoveFromConnection(
//                         userInfo?.userId,
//                         connectionInfo?.connectWithId
//                       )
//                     }
//                   >
//                     Yes, Remove
//                   </button>
//                   <button
//                     type="button"
//                     className="btn btn-outline-secondary"
//                     data-dismiss="modal"
//                     id="close_confirmation_window"
//                   >
//                     Cancel
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </MainContainer>
//     </>
//   );

// }

class MyConnection extends React.Component {
  constructor(props) {
    super(props);
    this.scheduleCallRef = React.createRef();
    this.state = {
      connectWithId: null,
      headline: null,
      birthday: null,
      city: null,
      state: null,
      country: null,
      description: null,
      email: null,
      phone: null,
      first_name: null,
      last_name: null,
      gender: null,
      industry: null,
      interested_in: null,
      classification: null,
      organization: null,
      organization_title: null,
      profile_banner: null,
      profile_picture: null,
      university: null,
      university_degree: null,
      zip: null,
      url: null,
      popupVisible: false,
      profileData: null,
      userInmyConnection: false,
      userConnectedCall: false,
    };
  }

  getUserData = (profile_key) => {
    axios
      .get(`${clientConfig.siteUrl}wl/v1/user_by_key/${profile_key}`)
      .then((response) => {
        if (response.status === 200 && response.data) {
          const {
            headline,
            city,
            state,
            country,
            description,
            email,
            first_name,
            last_name,
            gender,
            industry,
            interested_in,
            organization,
            organization_title,
            phone,
            profile_banner,
            profile_picture,
            university,
            university_degree,
            zip,
            url,
            classification,
            birthday,
            id,
          } = response.data;

          console.log(
            this.props.userInfo?.pending_connections.find(
              (item) =>
                parseInt(item.id) === parseInt(id) &&
                item.after_call_connection_request.accept
            )
          );

          this.setState({
            connectWithId: id,
            headline,
            birthday,
            city,
            state,
            country,
            description,
            email,
            phone,
            first_name,
            last_name,
            gender,
            industry,
            interested_in,
            classification,
            organization,
            organization_title,
            profile_banner,
            profile_picture,
            university,
            university_degree,
            zip,
            url,
            profileData: response.data,
            userInmyConnection: !!this.props.userInfo?.connections.find(
              (item) => parseInt(item.id) === parseInt(id)
            ),
            userConnectedCall: this.props.userInfo?.pending_connections.find(
              (item) =>
                parseInt(item.id) === parseInt(id) &&
                item.after_call_connection_request.accept
            ),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getUserData(this.props.match.params.id);

    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        this.state.popupVisible &&
        this.scheduleCallRef.current &&
        !this.scheduleCallRef.current.contains(e.target)
      ) {
        this.setState({
          popupVisible: false,
        });
        this.sendDatatoParent(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }

  location = (city, state, country, zip) => {
    let address = "";
    if (city) address += city;
    if (state) address += `, ${state}`;
    if (zip) address += `  ${zip}`;
    if (country) address += ` - ${country}`;
    return address;
  };

  CompanyPosition = (title, organization) => {
    let designation = title ? title : "";
    let company = organization ? ` at ${organization}` : "";
    return `${designation}${company}`;
  };

  education = (degree, school) => {
    let edu_degree = degree ? degree : "";
    let edu_school = school ? ` at ${school}` : "";
    return `${edu_degree}${edu_school}`;
  };

  sendDatatoParent = (value) => {
    this.setState({
      popupVisible: value,
    });
    console.log(value);
  };

  handleremoveFromConnection = (userId, connectwith_Id) => {
    const httpbody = {
      userId: userId,
      connectwithUserId: connectwith_Id,
    };

    const configheader = {
      "Content-Type": "application/json",
      Authorization: `Barer ${this.props.userInfo.token}`,
    };

    axios
      .post(`${clientConfig.siteUrl}wl/v1/remove-from-myconnection`, httpbody, {
        headers: configheader,
      })
      .then((response) => {
        if (response) {
          document.getElementById("close_confirmation_window").click();
          this.props.history.push("/");
          toast.success("User Removed Successfully");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong, please try again after some time");
      });
  };

  handleAddToConnection = (userId, connectWithId) => {
    const httpbody = {
      user_id: userId,
      connectwith_user_id: connectWithId,
      meeting_id: uuid(),
      // meeting_date: moment
      //   .tz(this.props.userInfo?.scheduleMeetingSettings?.timeZone?.value)
      //   .format("YYYY-MM-DD h:mm:ss")
    };

    const configheader = {
      "Content-Type": "application/json",
      Authorization: `Barer ${this.props.userInfo.token}`,
    };

    axios
      .post(
        `${clientConfig.siteUrl}wl/v1/profile-add-to-connection`,
        httpbody,
        {
          headers: configheader,
        }
      )
      .then((response) => {
        if (response) {
          document.getElementById("close_addConnection_confirmation_window").click();
          this.props.history.push("/connections");
          toast.success("Connection Request Sent Successfully");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong, please try again after some time");
      });
  };

  render() {
    const { userInfo } = this.props;
    const Location = this.location(
      this.state?.city,
      this.state?.state,
      this.state?.country,
      this.state?.zip
    );
    return (
      <>
        <MainContainer>
          {this.state.popupVisible ? (
            <>
              <h2 className="text-center w-100 mb-1 mt-4">
                {userInfo?.firstName}, Let's Meet
              </h2>
              <UserProfilePopup
                profile={this.state.profileData}
                popupVisible={this.state.popupVisible}
                sendDatatoParent={this.sendDatatoParent}
                nextbtnvisible={false}
                titleVisible={false}
                showCancelButton={true}
                requestFromMyConnection={true}
              />
            </>
          ) : null}
          <div className="my-profile-container px-3 my-4">
            <div className="row my-4">
              <div className="col-12 col-lg-8">
                <div className="my-profile-box">
                  <div className="row">
                    <div className="w-100">
                      <div
                        className="profile-banner"
                        style={{
                          backgroundImage: `url(${
                            this.state.profile_banner &&
                            this.state.profile_banner
                          })`,
                        }}
                      >
                        <div
                          className="profile-image"
                          style={{
                            backgroundImage: `url(${
                              this.state.profile_picture &&
                              this.state.profile_picture
                            })`,
                          }}
                        ></div>
                      </div>
                    </div>                    
                    {userInfo?.profile_unique_key !==
                    this.props.match.params.id ? (
                      <div className="w-100 text-right mt-n5 pt-2">
                        {this.state.userInmyConnection ? (
                          <p
                            className="editPopup"
                            data-toggle="modal"
                            data-target="#confirmationRemoveConnection"
                          >
                            <span className="text-danger d-flex align-items-center">
                              <RiDeleteBin6Line className="text-danger mr-1" />{" "}
                              Remove Connection
                            </span>
                          </p>
                        ) : this.state.userConnectedCall === "" ||
                          this.state.userConnectedCall === undefined ? (
                          <p
                            className="editPopup"
                            data-toggle="modal"
                            data-target="#confirmationAddConnection"
                          >
                            <span className="text-success d-flex align-items-center">
                              <IoMdAdd className="text-success mr-1" /> Add
                              Connection
                            </span>
                          </p>
                        ) : this.state.userConnectedCall
                            ?.after_call_connection_request?.status ===
                          "PENDING" ? (
                          <p
                            className="editPopup"
                            style={{ pointerEvents: "none" }}
                          >
                            <span className="text-primary d-flex align-items-center">
                              Pending Request
                            </span>
                          </p>
                        ) : null}

                        {userInfo?.email_verified ? (
                          <button
                            onClick={() =>
                              this.setState({
                                popupVisible: !this.state.popupVisible,
                              })
                            }
                            className="btn btn-primary rounded-0 px-4 btn-schedule-call mx-3"
                          >
                            Schedule a Call
                          </button>
                        ) : null}
                      </div>
                    ) : null}

                    <div className="col-12 col-md-6 col-lg-6 col-xl-7">
                      <h3 className="name h4 mb-1">
                        {this.state.first_name || this.state.last_name ? (
                          `${this.state?.first_name} ${this.state?.last_name}`
                        ) : (
                          <span className="placeholder_text">Full Name</span>
                        )}
                      </h3>

                      <p className="headline mb-0">
                        {this.state.headline ? (
                          this.state.headline
                        ) : (
                          <span className="placeholder_text">Headline</span>
                        )}
                      </p>
                      {this.state.userInmyConnection ? (
                        <p className="location mb-0">
                          {Location ? (
                            Location
                          ) : (
                            <span className="placeholder_text">Location</span>
                          )}
                        </p>
                      ) : (
                        <p className="location mb-0">
                          {this.state.city}, {this.state.state}
                        </p>
                      )}

                      {this.state.userInmyConnection ? (
                        <p className="location text-truncate mb-0">
                          {this.state?.url ? (
                            <a
                              style={{ color: "#3861b3" }}
                              href={this.state?.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {this.state?.url}
                            </a>
                          ) : null}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-5">
                      <ul className="workex_school">
                        <li>
                          {this.state.organization_thumb ? (
                            <div
                              className="thumb"
                              style={{
                                backgroundImage: `url(${this.state.organization_thumb})`,
                              }}
                            />
                          ) : (
                            <div className="thumb">
                              <BsBriefcase size="1.3em" color="#a7a7a7" />
                            </div>
                          )}
                          {this.state.organization_title &&
                          this.state.organization ? (
                            this.CompanyPosition(
                              this.state.organization_title,
                              this.state.organization
                            )
                          ) : (
                            <span className="placeholder_text">
                              Current Position at Company Name
                            </span>
                          )}
                        </li>
                        <li>
                          <div className="thumb">
                            <IoSchoolOutline size="1.6em" color="#a7a7a7" />
                          </div>
                          {this.state.university &&
                          this.state.university_degree ? (
                            this.education(
                              this.state.university_degree,
                              this.state.university
                            )
                          ) : (
                            <span className="placeholder_text">
                              Degree at School Name
                            </span>
                          )}
                        </li>
                      </ul>
                    </div>

                    <hr className="w-100 mb-4" />
                    <IconContext.Provider
                      value={{
                        color: "#333333",
                        size: "1.3em",
                        className: "profile_field_icons",
                      }}
                    >
                      {this.state.userInmyConnection ? (
                        <>
                          <div className="col-12 col-md-6">
                            <p>
                              <MdEmail />{" "}
                              {this.state.email ? (
                                this.state.email
                              ) : (
                                <span className="placeholder_text">
                                  Your Email
                                </span>
                              )}
                            </p>
                          </div>

                          <div className="col-12 col-md-6">
                            <p>
                              <BsTelephoneFill />{" "}
                              {this.state.phone ? (
                                formatPhoneNumber(this.state.phone)
                              ) : (
                                <span className="placeholder_text">
                                  Phone / Mobile Number
                                </span>
                              )}
                            </p>
                          </div>
                        </>
                      ) : null}

                      <div className="col-12 col-md-6">
                        <p>
                          <FaBirthdayCake />{" "}
                          {this.state.birthday ? (
                            this.state.birthday.split("-").join("/")
                          ) : (
                            <span className="placeholder_text">
                              Your Birthdate
                            </span>
                          )}
                        </p>
                      </div>

                      <div className="col-12 col-md-6">
                        <p>
                          <ImHappy2 />{" "}
                          {this.state.interested_in ? (
                            this.state.interested_in
                          ) : (
                            <span className="placeholder_text">
                              Your Interest
                            </span>
                          )}
                        </p>
                      </div>

                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <p>
                              <MdHomeWork />{" "}
                              {this.state.industry ? (
                                this.state.industry
                              ) : (
                                <span className="placeholder_text">
                                  Industy
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="col-12 col-md-6">
                            <p>
                              <BsStars />{" "}
                              {this.state.classification ? (
                                this.state.classification
                              ) : (
                                <span className="placeholder_text">
                                  Classifications
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </IconContext.Provider>
                  </div>
                </div>

                <div className="my-profile-about-box">
                  <div className="row">
                    <div className="col-12">
                      <h3 className="h4">About</h3>

                      <p className="m-0">
                        {this.state.description ? (
                          this.state.description
                        ) : (
                          <span className="placeholder_text text-mute">
                            Write about your years of experience, industry, or
                            skills. People also talk about their achievements or
                            previous job experiences.
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <ConnectionsWidget
                  connection={userInfo.connections}
                  widgetTitle="My Connections"
                  limit="5"
                  type="link"
                  morelink="/connections"
                  icontype="person"
                />
              </div>
            </div>

            <div
              className="modal fade"
              id="confirmationRemoveConnection"
              data-backdrop="static"
              data-keyboard="false"
              tabIndex="-1"
              aria-labelledby="confirmation_modal"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="confirmation_modal">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p className="m-0">
                      Are you sure you would like to remove{" "}
                      <b>{`${this.state?.first_name} ${this.state?.last_name}`}</b>{" "}
                      from your connections list?
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() =>
                        this.handleremoveFromConnection(
                          userInfo?.userId,
                          this.state?.connectWithId
                        )
                      }
                    >
                      Yes, Remove
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      data-dismiss="modal"
                      id="close_confirmation_window"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="confirmationAddConnection"
              data-backdrop="static"
              data-keyboard="false"
              tabIndex="-1"
              aria-labelledby="confirmation_modal"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="confirmation_modal">
                      Confirmation
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p className="m-0">
                      Would you like to add{" "}
                      <b>{`${this.state?.first_name} ${this.state?.last_name}`}</b>{" "}
                      to your connections?
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        this.handleAddToConnection(
                          userInfo?.userId,
                          this.state?.connectWithId
                        )
                      }
                    >
                      Yes, Connect
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      data-dismiss="modal"
                      id="close_addConnection_confirmation_window"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MainContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setcurrentUser: (user) => dispatch(setCurrentUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyConnection);
