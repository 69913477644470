const INITIAL_STATE = {
    token: "",
    userId: "",
    mycredits: 0,
    firstName: "",
    lastName: "",
    url: "",
    gender: "",
    email: "",
    phone: "",
    birthday: "",
    interested_in: "",
    industry: "",
    classification: "",
    about: "",
    headline: "",
    profile_picture: "",
    profile_banner: "",
    organization: "",
    organization_title: "",
    organization_thumb: "",
    university: "",
    university_degree: "",
    university_thumb: "",
    location: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    status: "",
    registerat: "",
    email_verified: "",
    profile_unique_key: "",
    using_invite_code:"",
    steps_form_complete: "",
    inviteCode:"",
    inviteCredit: "",
    connections: "",
    pending_connections: "",
    recentlyViewed: "",
    preferencesSettings: "",
    scheduleMeetingSettings: "",
    recommendedMatch: "",
}

const userreducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case "SET_CURRENT_USER":

            console.log(action.payload)

            return {
                ...state,
                token: action.payload.token,
                status: action.payload.status,
                userId: action.payload.id,
                firstName: action.payload.first_name,
                lastName: action.payload.last_name,
                url: action.payload.url,
                email: action.payload.email,
                phone: action.payload.phone,
                gender: action.payload.gender,
                birthday: action.payload.birthday,
                about: action.payload.description,
                headline: action.payload.headline,
                interested_in: action.payload.interested_in,
                industry: action.payload.industry,
                classification: action.payload.classification,
                university: action.payload.university,
                university_degree: action.payload.university_degree,
                university_thumb: action.payload.university_thumb,
                location: action.payload.address,
                country: action.payload.country,
                state: action.payload.state,
                city: action.payload.city,
                zip: action.payload.zip,
                // mycredits: action.payload.my_credits,
                organization: action.payload.organization,
                organization_title: action.payload.organization_title,
                organization_thumb: action.payload.organization_thumb,
                profile_picture: action.payload.profile_picture,
                profile_banner: action.payload.profile_banner,
                registerat: action.payload.registeredDate,
                email_verified: action.payload.email_verified,
                profile_unique_key: action.payload.profile_unique_key,
                using_invite_code: action.payload.using_invite_code,
                steps_form_complete: action.payload.steps_form_complete,
                inviteCode: action.payload.inviteCode,
                inviteCredit: action.payload.inviteCredit,
                connections: action.payload.myconnections ? action.payload.myconnections : [],
                pending_connections: action.payload.mypending_connections ? action.payload.mypending_connections : [],
                recentlyViewed: action.payload.recentlyViewed ? action.payload.recentlyViewed : [],
                preferencesSettings: action.payload["preferences-settings"] ? action.payload["preferences-settings"] : "",
                scheduleMeetingSettings: action.payload["schedule-meeting-settings"] ? action.payload["schedule-meeting-settings"] : "",
                recommendedMatch: action.payload["recommended-match"] ? action.payload["recommended-match"] : ""
            }
        case "SET_MY_CREDIT":
            return {
                ...state,
                mycredits: action.payload
            }

        case "UPDATE_MY_CREDIT":
            return {
                ...state,
                mycredits: state.mycredits - 1
            }
        case "SET_CONNECTIONS":
            return {
                ...state,
                ...action.payload
            }
        case "GET_CURRENT_USER_INFO":
            return {
                ...state,
                user: action.payload
            }
        default:
            return state;

    }
}

export default userreducer;