import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { AiOutlineHome } from "react-icons/ai"
import { GrSchedule } from "react-icons/gr"
import { AiOutlineSchedule } from "react-icons/ai"

import axios from 'axios';
import moment from 'moment/moment';
import "moment-timezone";
import clientConfig from "../../env";
import Logo from '../../components/logo/logo.components';
import "./call-schedule.styles.scss";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { checkDateIsPast } from '../../utilities/utilities';

const CallScheduled = () => {

    const history = useHistory();
    const params = useParams()
    const [ requestType, setRequestType] = useState(null)
    const [ icslink, setIcslink ] = useState(null);
    const [declineUsername, setDeclineUsername ] = useState();
    const [ meetingresponse, setMeetingresponse] = useState()

    function randomString() {
        // var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz!@$^*()_+=3for3networking";
        var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz3for3networking";
        var string_length = 32;
        var randomstring = '';
        for (var i = 0; i < string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum, rnum + 1);
        }
        return `3for3networkingMeetingInvite${randomstring}`;
    }

    const callSchedule = (values) => {

        const meeting_link =
          values.communicationType === "phone"
            ? ""
            : `https://video.3for3networking.com/${randomString()}`; ;
        const date = moment(values.date, ["MMMM Do YYYY"]).format("YYYY-MM-DD");
        const receiver_meeting_date = moment(values.receiver_meeting_date, [
          "YYYY-MM-DD hh:mm:ss",
        ]).format("YYYY-MM-DD");
        const utc_meeting_date = moment(values.utc_meeting_date, [
          "YYYY-MM-DD hh:mm:ss",
        ]).format("YYYY-MM-DD");

        const data = {
          meetinglink: values.meetinglink ? values.meetinglink : meeting_link,
          sendername: values.sender_id,
          senderemail: values.senderemail,
          receivername: values.request_user_id,
          receiveemail: values.requestemail,
          start_date: `${date} ${values.start_time}`,
          end_date: `${date} ${values.end_time}`,
          start_time: values.start_time,
          end_time: values.end_time,
          meeting_date: date,
          meeting_id: values.meeting_id,
          duration: values.duration,
          requestfrommyconnection: values.requestFromMyConnection,
          communicationType: values.communicationType,
          receiver_meeting_date: receiver_meeting_date,
          receiver_start_time: values.receiver_start_time,
          receiver_end_time: values.receiver_end_time,
          receiver_dtstart: `${receiver_meeting_date} ${values.receiver_start_time}`,
          receiver_dtend: `${receiver_meeting_date} ${values.receiver_end_time}`,
          utc_meeting_date: utc_meeting_date,
          utc_start_time: values.utc_start_time,
          utc_end_time: values.utc_end_time,
          utc_dtstart: `${utc_meeting_date} ${values.utc_start_time}`,
          utc_dtend: `${utc_meeting_date} ${values.utc_end_time}`,
        };

        console.log(data)
        console.log(values)

        const headers = {
            'Content-Type': 'application/json',
        }

        axios
        .post(`${clientConfig.siteUrl}wl/v1/generate-meeting`, data, {
            headers: headers,
        })
        .then((response) => {
            console.log(response)
            setMeetingresponse(response.data);
            setIcslink(response.data.icslink);
        });
    }

    const callDecline = (values) => {
        const date = moment(values.date).format("YYYY-MM-DD");
        const receiver_meeting_date = moment(values.receiver_meeting_date, [
          "YYYY-MM-DD hh:mm:ss",
        ]).format("YYYY-MM-DD");
        const utc_meeting_date = moment(values.utc_meeting_date, [
          "YYYY-MM-DD hh:mm:ss",
        ]).format("YYYY-MM-DD");
        const data = {
          sender_id: values.sender_id,
          sender_email: values.senderemail,
          request_id: values.request_user_id,
          meeting_id: values.meeting_id,
          duration: values.duration,
          communicationType: values.communicationType,
          meeting_date: values.date,
          start_date: `${date} ${values.start_time}`,
          end_date: `${date} ${values.end_time}`,
          receiver_meeting_date: receiver_meeting_date,
          receiver_dtstart: `${receiver_meeting_date} ${values.receiver_start_time}`,
          receiver_dtend: `${receiver_meeting_date} ${values.receiver_end_time}`,
          utc_meeting_date: utc_meeting_date,
          utc_dtstart: `${utc_meeting_date} ${values.utc_start_time}`,
          utc_dtend: `${utc_meeting_date} ${values.utc_end_time}`,
        };

        const headers = {
            'Content-Type': 'application/json',
        }

        axios.post(`${clientConfig.siteUrl}wl/v1/no-decline-call-request`, data, {
            headers: headers
        }).then(response => {
            console.log(response)
            setMeetingresponse(response.data);
            setDeclineUsername(response.data.data)
            // toast.success(response.data.message);
        })
    }

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if(params?.sc) {
          const values = JSON.parse(window.atob(params.sc));

          console.log(
            "values.meeting_date ",
            `${moment(values.meeting_date).format("YYYY-MM-DD")} ${moment(
              values.end_time,
              ["h:mm A"]
            ).format("HH:mm")}`
          );
          // if (
          //   moment(
          //     moment(
          //       moment(values.meeting_date).format("YYYY-DD-MM hh:mm:ss")
          //     ).unix()
          //   ).isBefore(moment(`${currentDate} ${timeformatter}`).unix())
          // )

          const meetingDate = `${moment(values.meeting_date).format(
            "YYYY-MM-DD"
          )} ${moment(values.end_time, ["h:mm A"]).format("HH:mm")}`;

          const currentDateTimeUnix = moment(moment().format("LLLL")).unix();
          const meetingDateTimeUnix = moment(
            moment(meetingDate).format("LLLL")
          ).unix();

          console.log(moment(meetingDateTimeUnix).isBefore(currentDateTimeUnix))

          // if (moment(meetingDateTimeUnix).isBefore(currentDateTimeUnix)) {
          if (checkDateIsPast(meetingDate)) {
            setMeetingresponse({
              request_type: "yes_confirm",
              meeting_past: true,
            });
          } else {
            setRequestType(params.req);

            if (params.req === "yes_confirm" && params.sc) {
              callSchedule(values);
            }

            if (params.req === "no_decline" && params.sc) {
              callDecline(values);
            }
          }
        }

    }, [])

    let content ;

    if(meetingresponse?.request_type === "yes_confirm" && meetingresponse?.meeting_past) {
      content = <MeetingIsOver />;
    } else if (meetingresponse?.request_type === "yes_confirm" && meetingresponse?.already_scheduled) {
        content = <MeetingAlreadyScheduled message={meetingresponse?.message} />;
    } else if (meetingresponse?.request_type === "yes_confirm") {
      content = <YesSchedule icslink={icslink} />;
    }

    if (
      meetingresponse?.request_type === "no_decline" ||
      (meetingresponse?.request_type === "yes_confirm" &&
        meetingresponse?.already_declined)
    ) {
      content = <AlreadyDecline message={meetingresponse?.message} />;
    } else if (meetingresponse?.request_type === "no_decline") {
      content = <NoDecline name={declineUsername} />;
    }
    
    return (
        <>
            <div className="call-schedule-container py-3  py-md-5">
            <div className="py-4 text-center">
                <Logo />
            </div>
            <div className="container-fluid">
                <div className="container">
                <div className="row">
                    {content}
                </div>
                </div>
            </div>
            </div>
        </>
    );
}

export default CallScheduled;

const YesSchedule = ({ icslink }) => {
    return(
        <div className="col-12 text-center">
            <h2 className="h2 mb-4 mt-5">Thank You!</h2>
            <p className="lead">Your meeting has been scheduled.</p>

            <p className="lead">You can add this meeting to your calendar with the confirmation email.</p>

            {icslink && <a href={icslink} download={true} className="btn btn-primary px-4 mt-4 mr-3 rounded-0 border-0 align-items-center" style={{ paddingTop: 12, paddingBottom: 12 }}><AiOutlineSchedule color="#ffffff" size="1.5em" style={{ marginRight: 8 }} /> Download .ics</a> }

            <Link to={"/"} className="btn btn-primary px-4 mt-4 rounded-0 border-0 align-items-center" style={{ paddingTop: 12, paddingBottom: 12 }}><AiOutlineHome className='white-txt'  size="1.5em" style={{ marginRight: 8 }} /> Return to Dashboard</Link>
        </div>
    )
}

const MeetingIsOver = () => {
  return (
    <div className="col-12 text-center">
      <h2 className="h2 mb-4 mt-5">Meeting Time Ended</h2>
      <p className="lead">
        The meeting is past the end time. Continue to schedule with new users to build your network.
      </p>

      <Link
        to={"/"}
        className="btn btn-primary px-4 mt-4 rounded-0 border-0 align-items-center"
        style={{ paddingTop: 12, paddingBottom: 12 }}
      >
        <AiOutlineHome
          className="white-txt"
          size="1.5em"
          style={{ marginRight: 8 }}
        />{" "}
        Return to Dashboard
      </Link>
    </div>
  );
}

const MeetingAlreadyScheduled = ({ message }) => {
  return (
    <div className="col-12 text-center">
      <h2 className="h2 mb-4 mt-5">Meeting is Scheduled</h2>
      <p className="lead">{message}</p>

      <Link
        to={"/"}
        className="btn btn-primary px-4 mt-4 rounded-0 border-0 align-items-center"
        style={{ paddingTop: 12, paddingBottom: 12 }}
      >
        <AiOutlineHome
          className="white-txt"
          size="1.5em"
          style={{ marginRight: 8 }}
        />{" "}
        Return to Dashboard
      </Link>
    </div>
  );
};

const NoDecline = ({ name }) => {
    return(
        <>
            <div className="col-12 text-center">
                <h2 className="h2 mb-4 mt-5">Meeting Cancelled</h2>
                <p className="lead">This invitation has been declined.</p>
                <Link to={"/"} className="btn btn-primary px-4 mt-4 rounded-0 border-0 align-items-center" style={{ paddingTop: 12, paddingBottom: 12 }}><AiOutlineHome size="1.5em" style={{ marginRight: 8 }} /> Return to Dashboard</Link>
            </div>
        </>
    )
}

const AlreadyDecline = ({ message }) => {
  return (
    <>
      <div className="col-12 text-center">
        <h2 className="h2 mb-4 mt-5">Meeting Request Declined</h2>
        <p className="lead">{message}</p>
        <Link
          to={"/"}
          className="btn btn-primary px-4 mt-4 rounded-0 border-0 align-items-center"
          style={{ paddingTop: 12, paddingBottom: 12 }}
        >
          <AiOutlineHome size="1.5em" style={{ marginRight: 8 }} /> Return to
          Dashboard
        </Link>
      </div>
    </>
  );
};