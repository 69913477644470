export default function Logo({size, className}) {

    const fontSize = () => {
        switch(size) {
            case "extraSmall":
                return "clamp(16px, 4vw, 18px)";
            case "small":
                return "clamp(22px, 5vw, 30px)";
            default:
                return "clamp(28px, 5vw, 40px)";
        }
    }

    const Styles = {
      loginLogo: {
        color: "#ffffff",
        fontSize: fontSize(),
      },
    };

    return(
        <span className={`d-block font-weight-bold ${className ? className : ''}`} style={Styles.loginLogo}>
            3For3 <span style={{ color: "#3861b3" }}>Networking</span>
        </span>
    )
}