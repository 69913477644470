import React, { useState } from "react";
import "./dashboard.styles.scss";

// images
import AppleStore from "../../images/apple-store.png";
import GooglePlayStore from "../../images/google-playstore.png";

//icons
import { FaPhoneAlt } from "react-icons/fa";
import { IoChatbubblesOutline } from "react-icons/io5";
import { SiJitsi } from "react-icons/si";

//components
import MainContainer from "../../components/main-container/main-container.component";
import { useSelector } from "react-redux";
import Table4Member from "../../images/1-table-4-chair.png";
import Table6Member from "../../images/1-table-6-chair.png";
import Table8Member from "../../images/1-table-8-chair.png";

const Dashboard = ({ props }) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const firstname = useSelector((state) => state.user.firstName);
  const userCredit = useSelector((state) => state.user.mycredits);
  const status = useSelector((state) => state.user.status);
  const email_verified = useSelector((state) => state.user.email_verified);

  const callSchedulefromParent = () => {
    setPopupVisible(!popupVisible);
  };

  const handleJoinTable = (tableId) => {
    window.open(`https://video.3for3networking.com/${tableId}`, "_blank");
  };

  return (
    <>
      <MainContainer
        callSchedulefromParent={callSchedulefromParent}
        popupVisible={popupVisible}
      >
        {/* { popupVisible && <UserProfilePopup profile={profileData} popupVisible={popupVisible} sendDatatoParent={sendDatatoParent} NextProfileClickToParent={NextProfileClickToParent} loading={profileDataLoaded}/>} */}

        <div className="dashboard-container px-0 py-3 mx-0 mx-md-3 my-3 my-md-4">
          <h1 className="title">Welcome, {firstname}.</h1>
          <div className="welcome-subtitle">Let's Get You Connecting!</div>

          <button
            className={`btn btn-primary px-5 py-2 rounded-0 mt-3 schedule_btn_circle ${
              (!!!userCredit || email_verified === null) && "disabled"
            }`}
            onClick={callSchedulefromParent}
            disabled={
              !!!userCredit || email_verified === null
                ? true
                : false || status === "Inactive"
            }
          >
            Schedule a Call
          </button>

          <ul className="list-group list-group-horizontal justify-content-center mt-3">
            <li className="list-group-item pr-2 pl-0 py-0 border-0 font-weight-bold">
              Learn Something.
            </li>
            <li className="list-group-item px-2 py-0 border-0 font-weight-bold">
              Teach Something.
            </li>
            <li className="list-group-item px-2 py-0 border-0 font-weight-bold">
              Help Someone.
            </li>
          </ul>

          <div className="border-0 my-0 py-4">
            <h5
              className="howitworks-title px-4 py-2"
              data-toggle="modal"
              data-target="#howitworksmodal"
            >
              <span className="phone-icon">
                <FaPhoneAlt size="0.7em" color="green" />
              </span>{" "}
              How It Works
            </h5>
          </div>

          <h5
            className="questions-title px-4 py-2"
            data-toggle="modal"
            data-target="#questionsmodal"
          >
            <span className="chat-icon">
              <IoChatbubblesOutline size="1.4em" color="red" />
            </span>{" "}
            The Questions
          </h5>

          <div className="row mt-5">
            <div className="col-12">
              <h2 className="title">Join the Table</h2>
              {!email_verified ? (
                <div className="welcome-subtitle mb-0 text-danger">
                  Verify your email to join.
                </div>
              ) : (
                <div className="welcome-subtitle mb-0">
                  Double click to join.
                </div>
              )}
            </div>
            <div
              className={`col-12 col-md-4 ${
                email_verified ? "cursor-pointer" : ""
              }`}
              onDoubleClick={
                email_verified ? () => handleJoinTable("table1") : () => {}
              }
            >
              <div className="p-4">
                <img
                  src={Table4Member}
                  className="img-fluid mb-2 p-4"
                  alt="Table 1"
                />
                <div className="font-weight-bold">Table 1</div>
              </div>
            </div>
            <div
              className={`col-12 col-md-4 my-5 my-md-0 ${
                email_verified ? "cursor-pointer" : ""
              }`}
              onDoubleClick={
                email_verified ? () => handleJoinTable("table2") : () => {}
              }
            >
              <div className="p-4">
                <img
                  src={Table6Member}
                  className="img-fluid mb-2 p-4"
                  alt="Table 2"
                />
                <div className="font-weight-bold">Table 2</div>
              </div>
            </div>
            <div
              className={`col-12 col-md-4 ${
                email_verified ? "cursor-pointer" : ""
              }`}
              onDoubleClick={
                email_verified ? () => handleJoinTable("table3") : () => {}
              }
            >
              <div className="p-4">
                <img
                  src={Table8Member}
                  className="img-fluid mb-2 p-4"
                  alt="Table 3"
                />
                <div className="font-weight-bold">Table 3</div>
              </div>
            </div>
          </div>

          <div className="row mt-5" id="app_download_sec">
            <div className="col-12 col-lg-6 mx-auto">
              <p>
                <SiJitsi fontSize={"3em"} />
              </p>
              <p className="font-weight-bold" id="info">
                We have partnered with Jitsi for the ultimate webchat
                experience. Download the app.
              </p>

              <div className="row justify-content-center" id="logos">
                <div className="col-12 col-sm-6 col-md-4 col-lg-6 my-2 text-lg-right">
                  <a
                    href="https://apps.apple.com/us/app/jitsi-meet/id1165103905"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={AppleStore}
                      alt="Apple store"
                      className="img-fluid"
                    />
                  </a>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-6 my-2 text-lg-left">
                  <a
                    href="https://play.google.com/store/apps/details?id=org.jitsi.meet&pli=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={GooglePlayStore}
                      alt="Google play store"
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="howitworksmodal"
            tabIndex="-1"
            aria-labelledby="howitworksLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="howitworksLabel">
                    How It Works
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="embed-responsive embed-responsive-16by9">
                          <iframe
                            src="https://www.youtube.com/embed/sgxJXxQIm4M?controls=0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <ul className="howitworks-list">
                          <li>Schedule a time that is convenient for you.</li>
                          <li>Engage on the call.</li>
                          <li>Build your network with new connections.</li>
                        </ul>

                        <p className="text-uppercase m-0 text-left">
                          It all starts with one 15 minute introductory call.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="questionsmodal"
            tabIndex="-1"
            aria-labelledby="questionsLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="questionsLabel">
                    The Questions
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 mb-5 mb-xl-0">
                        <div className="embed-responsive embed-responsive-16by9">
                          <iframe
                            src="https://www.youtube.com/embed/Q108HpHdC8g?controls=0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className="col-md-6 text-left">
                        <p className="questions-subtitle">
                          These are mererly icebreaker questions for your first
                          call but they are also some of the most powerful
                          conversation questions for business.
                        </p>

                        <p>What is your story? Tell me about your work.</p>

                        <p>What is something not everyone knows about you?</p>

                        <p>How can I help you?</p>

                        <p className="text-uppercase mb-4">
                          Bringing <strong>Real</strong> people together for{" "}
                          <strong>Real</strong> conversations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContainer>
    </>
  );
};

// const mapStateToProps = state => ({
//     myCredits : state.user.mycredits,
//     status: state.user.status
// })

// const mapDispatchToProps = dispatch => ({
//     //setMyCredit: () => dispatch(setMyCredit())
// })

// export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export default Dashboard;
