import React, { useEffect, useState } from 'react';
import './verify-email.scss';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import clientConfig from '../../env';
import { generateEncryptKey } from '../../utilities/utilities';

const VerifyEmail = () => {

    const [response, setResponse] = useState('');
    const history = useHistory();

    const getVerified = (email, hash) => {
        axios.post(`${clientConfig.siteUrl}wl/v1/verify`, {
            email,
            hash,
            secretkey: generateEncryptKey(email.toLowerCase())
        }).then(response => {
            setResponse(response.data);
        })
        .catch( error => console.log(error) );
    }

    useEffect( () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if(params.email && params.hash) {
            getVerified(params.email, params.hash);
        } else {
            history.push("/");
        }
    },[history])

    return(
        <div className="verify-container">
            <h1 className="mb-2 mb-md-3 font-weight-bold login-logo">3For3 <span style={{ color: "rgb(56, 97, 179)" }}>Networking</span></h1>

            {response !== '' ?
                response.status === 200 ?
                    <React.Fragment>
                        <p className="title">{response.message}</p>
                        <Link className="btn btn-primary py-2 px-4 rounded-0" to='/'>Login to start networking</Link>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <p className="title">{response.message}</p>
                    </React.Fragment>
                :
                <React.Fragment>
                    <p className="title">Please wait we are checking your data...</p>
                </React.Fragment>
            }
        </div>
    )
}

export default VerifyEmail;