import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import clientConfig from '../../env';

import { FiEdit2 } from "react-icons/fi"

const UserDetails = ({ nextStep, prevStep, handleChange, values, token, userId, username, bannerImg, profileImg, getLatestData, handleCheckbox, checked }) => {

    const [isDisabled, setIsDisabled] = useState(true);
    const [banner, setBanner] = useState(bannerImg || "");
    const [profile, setProfile] = useState(profileImg || "");

    useEffect( () => {
        const { headline, company, designation } = values;
        if ( headline && company && designation  && profile) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [values])

    const mediaUploadWp = (data) => {

        const configheader = {
            "Content-Type": 'multipart/form-data',
            "Authorization": `Barer ${token}`,
        }

        axios.post(`${clientConfig.siteUrl}wl/v1/media-upload/${userId}`, data, {
            headers: configheader
        }).then(response => {
            toast.success(response.data.message);
            getLatestData()
        }).catch(error => {
            toast.error(error.response);
        });
    }

    const handleImageUpload = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                if (e.target.name === "profile_banner") {
                    setBanner(reader.result);
                    let formdata = new FormData();
                    formdata.append("profile_banner", e.target.files[0])
                    mediaUploadWp(formdata);
                } else if (e.target.name === "profile_picture") {
                    setProfile(reader.result);
                    let formdata = new FormData();
                    formdata.append("profile_picture", e.target.files[0])
                    mediaUploadWp(formdata);
                }
            }
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    const buttons = (
      <>
        <button
          onClick={prevStep}
          className="mt-3 btn btn-outline-primary rounded-0 px-5 mr-3"
        >
          Back
        </button>
        <button
          onClick={nextStep}
          disabled={isDisabled}
          className={`mt-3 btn btn-primary rounded-0 px-5 ${
            isDisabled ? "btn_disabled" : ""
          }`}
        >
          Next
        </button>
      </>
    );
    
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-6 text-left mt-0 mt-lg-5 mb-3 mb-lg-0">
            <h2
              className="mb-3"
              style={{ fontSize: "clamp(28px, 5vw, 36px)", fontWeight: "700" }}
            >
              How would you like your profile to be seen?
            </h2>

            <p style={{ fontSize: "clamp(15px, 5vw, 18px)" }}>
              Adding a professional headshot, a quality tagline that explains
              what you do and your current job position and company helps with
              your professional persona.
            </p>

            <div className="d-none d-lg-block">{buttons}</div>
          </div>

          <div className="col-12 col-lg-5 offset-lg-1">
            <div className="rounded-container userdetails">
              <div
                className="coverimage"
                // style={{
                //     backgroundImage: `url(${banner ? banner : bannerImg})`,
                //  }}
              >
                {/* <input 
                                type="file" 
                                id="profile-banner" 
                                name='profile_banner' 
                                className="input-file-upload" 
                                accept='image/*'
                                onChange={handleImageUpload} 
                            />
                            <label
                                htmlFor="profile-banner"
                                className="input-file-editIcon"
                            >
                                <FiEdit2 />
                            </label> */}

                <div
                  className="profileimage"
                  style={{
                    backgroundImage: `url(${profile ? profile : profileImg})`,
                  }}
                >
                  <input
                    type="file"
                    name="profile_picture"
                    id="input-profile-thumb"
                    className="input-file-upload"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  <label
                    htmlFor="input-profile-thumb"
                    className="input-file-editIcon"
                  >
                    <FiEdit2 />
                  </label>
                </div>
              </div>

              <div className="inner-container">
                <div className="form-group">
                  <h3 className="name">
                    {username ? username : ""}
                  </h3>
                </div>

                <div className="form-group">
                  <label>
                    Headline <span className="text-danger">*</span>
                  </label>
                  <textarea
                    maxLength={160}
                    name="headline"
                    value={values.headline}
                    onChange={handleChange("headline")}
                    className="form-control"
                    placeholder="Enter your headline"
                    rows="2"
                  ></textarea>
                </div>

                <div className="form-group">
                  <h6 className="mb-3">Current Position</h6>
                  <label>
                    Title <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={50}
                    value={values.designation}
                    onChange={handleChange("designation")}
                    className="form-control"
                    placeholder="Enter your title"
                  />
                </div>

                <div className="form-group">
                  <label>
                    Company <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={50}
                    value={values.company}
                    onChange={handleChange("company")}
                    className="form-control"
                    placeholder="Enter company name"
                  />
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={checked}
                    id="donthavecompany"
                    onChange={handleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="donthavecompany">
                    I don't have a company
                  </label>
                </div>
              </div>
            </div>
            <div className="d-block d-lg-none mt-3 mt-lg-0">{buttons}</div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default UserDetails;