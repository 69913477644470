import React, { useState, useEffect } from 'react';
import SignUp from '../login-signup/signup/signup.components';
import Logo from '../../components/logo/logo.components';

const Register = () => {

    const [registerVisible, setRegisterVisible] = useState(false);

    const sendDataToParent = (visiblebool) => {
        setRegisterVisible(visiblebool);
    };
    const [invitekey, setInvitekey] = useState(null);
    const [inviterName, setInviterName] = useState("");

    useEffect( () => {
        let paramsString = window.location.search;
        let searchParams = new URLSearchParams(paramsString);

        if ( searchParams.has('invite') ) {
            const code = searchParams.get('invite');
            setInviterName(code.slice(0, code.lastIndexOf("-")).split("-").join(" "));
            setInvitekey(code);
            return;
        }

    },[]);

    return (
      <React.Fragment>
        <article className="conatiner-fluid h-100" style={{ backgroundColor: "#212529" }}>
          <div className="container h-100 d-flex align-items-center">
            <div className="row h-md-100 align-items-center px-0 px-sm-4">
              <div className="col-12 col-md-12 col-lg-7 offset-lg-3 mb-4 text-center">
                <Logo className="mb-2 mb-md-3" />
                <SignUp
                  registerVisible={registerVisible}
                  sendDataToParent={sendDataToParent}
                  closeVisible={false}
                  invitekey={invitekey}
                  inviterName={inviterName}
                />
              </div>
            </div>
          </div>
        </article>
      </React.Fragment>
    );
}

export default Register;