import React from 'react';
import './custom-button.styles.scss';

const CustomButton = ({ children, otherClass,...otherProps }) => (
    <button className={`custom-button ${otherClass}`} {...otherProps}>
        {children}
    </button>
)

export default CustomButton;
