import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { AiOutlineHome } from "react-icons/ai"
import { GrSchedule } from "react-icons/gr"
import { AiOutlineSchedule } from "react-icons/ai"

import axios from 'axios';
import moment from 'moment/moment';
import clientConfig from "../../env";
import Logo from '../../components/logo/logo.components';
import "./after-call-connect.scss";
import UserProfilePopup from '../../components/user-profile-popup/user-profile-popup';

const AfterCallConnect = () => {

    // const history = useHistory();
    const [requestType, setRequestType] = useState(null)
    // const [icslink, setIcslink] = useState(null);
    const [declineUsername, setDeclineUsername] = useState();
    const [profileData, setProfileData] = useState()
    const [response, setResponse] = useState(null)

    const yesConnectMe = (values) => {
        const data = { ...values, request_type: "yes_confirm" }
        const headers = {
            'Content-Type': 'application/json',
        }

        console.log({data})
        axios.post(`${clientConfig.siteUrl}wl/v1/aftercall-request-pending`, data, {
            headers: headers
        }).then(response => {
            console.log(response)
            setResponse(response?.data)
        })
    }

    const callDecline = (values) => {

        const data = { ...values, request_type: "no_decline" }

        const headers = {
            'Content-Type': 'application/json',
        }

        console.log("no decline ", data)

        axios.post(`${clientConfig.siteUrl}wl/v1/aftercall-request-pending`, data, {
            headers: headers
        }).then(response => {
            setResponse(response?.data)
            // toast.success(response.data.message);
        })
    }

    const rescheduleGetUserInfo = (values) => {

        const data = { ...values, request_type: "reschedule" }

        const headers = {
            'Content-Type': 'application/json',
        }

        axios.get(`${clientConfig.siteUrl}wl/v1/user/${data.connectwith_user_id}`)
            .then(response => {
                if (response.status === 200) {
                    setProfileData({
                      ...response.data,
                      sender_id: data?.user_id,
                    });
                    setDeclineUsername(`${response?.data?.first_name} ${response?.data?.last_name}`)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        setRequestType(params.req);

        if (params.req === "yes_confirm" && params.sc) {
            const values = JSON.parse(window.atob(params.sc));
            yesConnectMe(values);
        }

        if (params.req === "no_decline" && params.sc) {
            const values = JSON.parse(window.atob(params.sc));
            callDecline(values);
        }

        if (params.req === "reschedule" && params.sc) {
            const values = JSON.parse(window.atob(params.sc));
            rescheduleGetUserInfo(values);
        }

    }, [])

    return (
        <>
            <div className="call-schedule-container py-3  py-md-5">
                <div className="py-4 text-center">
                    <Logo />
                </div>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            {requestType === "yes_confirm" && <YesSchedule response={response} />}
                            {requestType === "no_decline" && <NoDecline response={response} />}
                            {requestType === "reschedule" && <Reschedule name={declineUsername} profileData={profileData}/>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AfterCallConnect;

const YesSchedule = ({ response }) => {
    return (
      <div className="col-12 text-center">
        <h2 className="h2 mb-4 mt-5">{response?.title}</h2>
        <p className="lead">{response?.message}</p>

        {response?.success ? (
          <Link
            to={"/"}
            className="btn btn-primary px-4 mt-4 rounded-0 border-0 align-items-center"
            style={{ paddingTop: 12, paddingBottom: 12 }}
          >
            <AiOutlineHome
              className="white-txt"
              size="1.5em"
              style={{ marginRight: 8 }}
            />{" "}
            Return to Dashboard
          </Link>
        ) : null}
      </div>
    );
}

const NoDecline = ({ response }) => {
    return (
      <>
        <div className="col-12 text-center">
          <h2 className="h2 mb-4 mt-5">{response?.title}</h2>
          <p className="lead">{response?.message}</p>
          {response?.success ? (
            <Link
              to={"/"}
              className="btn btn-primary px-4 mt-4 rounded-0 border-0 align-items-center"
              style={{ paddingTop: 12, paddingBottom: 12 }}
            >
              <AiOutlineHome size="1.5em" style={{ marginRight: 8 }} /> Return
              to Dashboard
            </Link>
          ) : null}
        </div>
      </>
    );
}

const Reschedule = ({ name, profileData }) => {
    return(
        <>
            <h2 className='text-center w-100 mb-4'>Reschedule with {name}</h2>

            <UserProfilePopup profile={profileData} popupVisible={true} nextbtnvisible={false} titleVisible={false} otherClassName="static_reschedule_popup" showCancelButton={false} />
        </>
    )
}