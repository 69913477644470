import React from 'react';
import './user-dropdown.styles.scss';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Icon
import { FiCalendar, FiChevronDown, FiPower } from 'react-icons/fi';
import { CgPassword } from 'react-icons/cg';
import { FaUser } from "react-icons/fa";

import Modal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../modal/modal.components";

class UserDropdown extends React.Component {
  constructor() {
    super();
    this.menuRef = React.createRef();
    this.state = {
      isActiveDropdown: false,
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount = () => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        this.state.isActiveDropdown &&
        this.menuRef.current &&
        !this.menuRef.current.contains(e.target)
      ) {
        this.setState({
          isActiveDropdown: false,
        });
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  };

  logout = (e) => {
    e.preventDefault();
    let message = "Are you sure you want to logout?";
    if (window.confirm(message) === true) {
      this.setState(
        {
          isActiveDropdown: !this.state.isActiveDropdown,
        },
        () => {
          sessionStorage.removeItem("persist:root");
          window.location.href = window.location.origin;
        }
      );
    } else {
      this.setState({
        isActiveDropdown: !this.state.isActiveDropdown,
      });
    }
  };

  logmeout = () => {
    sessionStorage.removeItem("persist:root");
    window.location.href = window.location.origin;
    this.toggle();
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({
      isActiveDropdown: !this.state.isActiveDropdown,
    });
  };

  fullname = (firstname, lastname) => {
    let name = "";
    if (firstname) {
      name += firstname;
    }
    if (lastname) {
      name += ` ${lastname}`;
    }
    return name;
  };

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  render() {
    const { mycredits, firstName, lastName, profileThumb } = this.props;
    const name = this.fullname(firstName, lastName);
    return (
      <>
        <div className="col col-sm-6 col-md-5 col-lg-4">
          <div className="user-dropdown-container" ref={this.menuRef}>
            <div className="user-dropdown" onClick={this.handleClick}>
              <div className="d-flex no-gutters">
                <div
                  className="thumbnail"
                  style={{
                    backgroundImage: `url(${profileThumb})`,
                  }}
                ></div>
                <div className="w-100 d-none d-sm-block">
                  <span className="name px-2">{name ? name : "User Name"}</span>
                  <div className="d-flex no-gutters">
                    <span className="credits px-2">
                      {mycredits || 0} Credits
                    </span>
                    {/* <span className="time px-2">02:13:05</span> */}
                  </div>
                </div>
              </div>
              <div className="icon">
                <FiChevronDown size="14px" />
              </div>
            </div>
            <div
              className={`dropdown-menu dropdown-menu-right ${
                this.state.isActiveDropdown ? "active" : ""
              }`}
              aria-labelledby="dropdownMenuOffset"
            >
              <Link className="dropdown-item" to="/my-profile">
                <FaUser className="mr-2" /> My Profile
              </Link>
              <Link className="dropdown-item" to="/change-password">
                <CgPassword className="mr-2" /> Change Password
              </Link>
              <Link className="dropdown-item" to="/schedule-settings">
                <FiCalendar className="mr-2" /> Schedule Settings
              </Link>
              {/* <button className="dropdown-item" onClick={this.logout}>
                <FiPower className="mr-2" /> Log Out
              </button> */}
              <button
                type="button"
                className="dropdown-item"
                onClick={this.toggle}
              >
                <FiPower className="mr-2" /> Log Out
              </button>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal}>
          <ModalHeader>
            <h5>Logout</h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.toggle}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody>
            <p>Are you sure you want to logout?</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary rounded-0 px-4"
              onClick={this.toggle}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary rounded-0 px-4"
              onClick={this.logmeout}
            >
              Logout
            </button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
    mycredits: state.user.mycredits,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    profileThumb: state.user.profile_picture
});

export default connect(mapStateToProps)(UserDropdown);