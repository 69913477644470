export const setCurrentUser = (user) => ({
    type: "SET_CURRENT_USER",
    payload: user
});

export const setMyCredit = (credit) => ({
    type: "SET_MY_CREDIT",
    payload: credit
});

export const updateMyCredit = (credit) => ({
    type: "UPDATE_MY_CREDIT",
    payload: credit
});

export const setConnections = (connections) => ({
    type: "SET_CONNECTIONS",
    payload: connections
});

export const getCurrentUserInfo = (user) => ({
    type: "GET_CURRENT_USER_INFO",
    payload: user
});