import React from "react";
import "./my-profile.styles.scss";

import { IconContext } from "react-icons";
import { FiEdit2 } from "react-icons/fi";
import { MdEmail, MdHomeWork } from "react-icons/md";
import { BsTelephoneFill, BsStars, BsBriefcase } from "react-icons/bs";
import { FaBirthdayCake } from "react-icons/fa";
import { ImHappy2 } from "react-icons/im";
import { IoSchoolOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { setCurrentUser } from '../../redux/user/user.actions';
import { toast } from 'react-toastify';

//components
import MainContainer from "../../components/main-container/main-container.component";
// import EditableInput from "../../components/inline-editable-input/inline-editable-input.components";
import MyProfilePopup from "./my-profile-popup.components";
import ConnectionsWidget from "../../components/connections/connections.component";

import axios from 'axios';
import clientConfig from '../../env';

import { formatPhoneNumber } from "../../utilities/utilities";
import { RiDeleteBin6Line } from "react-icons/ri";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../../components/modal/modal.components";

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
    this.state = {
      username: null,
      headline: null,
      location: null,
      company: null,
      university: null,
      aboutme: null,
      profile_picture: null,
      profile_banner: null,
      myconnections: null,
      phone: null,
      email: null,
      birthday: null,
      industry: null,
      interestedIn: null,
      classification: null,
      linkedInUrl: null,
      EditwindowVisible: false,
      deleteProfile: false,
    };
    this.toggleDeleteConfirmationWindow = this.toggleDeleteConfirmationWindow.bind(this);
  }

  mediaUploadWp = (data) => {
    
    const configheader = {
      "Content-Type": 'multipart/form-data',
      "Authorization": `Barer ${this.props.userInfo.token}`,
    }

    axios.post(`${clientConfig.siteUrl}wl/v1/media-upload/${this.props.userInfo.userId}`, data, {
      headers: configheader
    }).then( response => {
      toast.success(response.data.message);
      this.getLatestData()
    }).catch( error => {
      toast.error(error.response);
    });
  }

  getLatestData = () => {
    axios.get(`${clientConfig.siteUrl}wl/v1/user/${this.props.userInfo.userId}`)
      .then(res => {
        let loginInfo = { token: this.props.userInfo.token }
        this.props.setcurrentUser({ ...loginInfo, ...res.data })
      }).catch(err => console.error(err));
  }

  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  handleImageUpload = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({
          [e.target.name]: reader.result,
        }, () => {
          let formdata = new FormData();
          formdata.append(e.target.name,  e.target.files[0])
          this.mediaUploadWp(formdata);
        });
      }
    };
    reader.readAsDataURL(e.target.files[0]);

  };

  sendDataToParent = (visiblebool) => {
    this.setState({
      EditwindowVisible: visiblebool
    });
  };

  handleEditPopup = () => {
    this.setState({
      EditwindowVisible: !this.state.EditwindowVisible
    });
  }

  location = (city, state, country, zip) => {
    let address = "";
    if(city) address += city;
    if(state) address += `, ${state}`;
    if(zip) address += `  ${zip}`;
    if(country) address += ` - ${country}`;
    return address;
  }

  CompanyPosition = (title, organization) => {
    let designation = title ? title : "";
    let company = organization ? ` at ${organization}` : "";
    return `${designation}${company}`
  }

  education = (degree, school) => {
    let edu_degree = degree ? degree : "";
    let edu_school = school ? ` at ${school}` : "";
    return `${edu_degree}${edu_school}`;
  }

  // formatPhoneNumber(phoneNumberString) {
  //   var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  //   var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     var intlCode = (match[1] ? '+1 ' : '');
  //     return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  //   }
  //   return null;
  // }

  toggleDeleteConfirmationWindow = () => {
    this.setState({
      deleteProfile: !this.state.deleteProfile,
    });
  }

  handleDeleteProfile = (userId, connections, pendingConnections) => {
    const httpbody = {
      data: {
        id: userId,
        myconnections: JSON.stringify(connections),
        mypendingconnections: JSON.stringify(pendingConnections),
      }
    };

    const configheader = {
      "Content-Type": "application/json",
      Authorization: `Barer ${this.props.userInfo.token}`,
    };

    axios
      .delete(
        `${clientConfig.siteUrl}wl/v1/user_delete/${userId}`,
        httpbody,
        configheader
      )
      .then((response) => {
        if (response) {
          sessionStorage.removeItem("persist:root");
          window.location.href = window.location.origin;
          this.toggleDeleteConfirmationWindow()
        }
      })
      .catch((error) => {
        toast.error("Something went wrong, please try again after some time");
      });
  }

  render() {
    const { userInfo } = this.props;
    const Location = this.location(this.props.userInfo?.city, this.props.userInfo?.state, this.props.userInfo?.country, this.props.userInfo?.zip);
    return (
      <>
        <MainContainer>
          <div className="my-profile-container px-md-3 my-3 my-md-4">
            <div className="row my-md-4">
              <div className="col-12 col-lg-8">
                <div className="my-profile-box">
                  <div className="row">
                    <div className="w-100">
                      <div
                        className="profile-banner"
                        // style={{
                        //   backgroundImage: `url(${
                        //     this.state.profile_banner ? this.state.profile_banner : userInfo.profile_banner
                        //   })`,
                        // }}
                      >
                        {/* <input
                        type="file"
                        name="profile_banner"
                        id="input-profle-banner"
                        className="input-file-upload"
                        accept="image/*"
                        onChange={this.handleImageUpload}
                      />
                      <label
                        htmlFor="input-profle-banner"
                        className="input-file-editIcon"
                      >
                        <FiEdit2 />
                      </label> */}
                        <div
                          className="profile-image"
                          style={{
                            backgroundImage: `url(${
                              this.state.profile_picture
                                ? this.state.profile_picture
                                : userInfo.profile_picture
                            })`,
                          }}
                        >
                          <input
                            type="file"
                            name="profile_picture"
                            id="input-profile-thumb"
                            accept="image/*"
                            onChange={this.handleImageUpload}
                          />
                          <label htmlFor="input-profile-thumb">
                            <FiEdit2 />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="w-100 text-right mt-n5 pt-2">
                      <p className="editPopup">
                        <span
                          className="text-danger d-flex align-items-center"
                          onClick={this.toggleDeleteConfirmationWindow}
                        >
                          <RiDeleteBin6Line className="text-danger mr-1" />{" "}
                          Delete Profile
                        </span>
                      </p>
                      <p className="editPopup" onClick={this.handleEditPopup}>
                        <FiEdit2 className="mr-1" /> Edit Profile
                      </p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-7">
                      <h3 className="name h4 mb-1">
                        {userInfo.firstName || userInfo.lastName ? (
                          `${userInfo?.firstName} ${userInfo?.lastName}`
                        ) : (
                          <span className="placeholder_text">Full Name</span>
                        )}
                      </h3>

                      <p className="headline mb-0">
                        {userInfo.headline ? (
                          userInfo.headline
                        ) : (
                          <span className="placeholder_text">Headline</span>
                        )}
                      </p>

                      <p className="location mb-0">
                        {Location ? (
                          Location
                        ) : (
                          <span className="placeholder_text">Location</span>
                        )}
                      </p>

                      <p className="location text-truncate mb-0">
                        {userInfo?.url ? (
                          <a
                            style={{ color: "#3861b3" }}
                            href={userInfo?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {userInfo?.url}
                          </a>
                        ) : (
                          null
                        )}
                      </p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-5">
                      <ul className="workex_school">
                        <li>
                          {userInfo.organization_thumb ? (
                            <div
                              className="thumb"
                              style={{
                                backgroundImage: `url(${userInfo.organization_thumb})`,
                              }}
                            />
                          ) : (
                            <div className="thumb">
                              <BsBriefcase size="1.3em" color="#a7a7a7" />
                            </div>
                          )}
                          {userInfo.organization_title &&
                          userInfo.organization ? (
                            this.CompanyPosition(
                              userInfo.organization_title,
                              userInfo.organization
                            )
                          ) : (
                            <span className="placeholder_text">
                              Current Position at Company Name
                            </span>
                          )}
                        </li>
                        <li>
                          <div className="thumb">
                            <IoSchoolOutline size="1.6em" color="#a7a7a7" />
                          </div>
                          {userInfo.university && userInfo.university_degree ? (
                            this.education(
                              userInfo.university_degree,
                              userInfo.university
                            )
                          ) : (
                            <span className="placeholder_text">
                              Degree at School Name
                            </span>
                          )}
                        </li>
                      </ul>
                    </div>

                    <hr className="w-100 mb-4" />
                    <IconContext.Provider
                      value={{
                        color: "#333333",
                        size: "1.3em",
                        className: "profile_field_icons",
                      }}
                    >
                      <div className="col-12 col-md-6">
                        <p>
                          <MdEmail />{" "}
                          {userInfo.email ? (
                            userInfo.email
                          ) : (
                            <span className="placeholder_text">Your Email</span>
                          )}
                        </p>
                      </div>

                      <div className="col-12 col-md-6">
                        <p>
                          <BsTelephoneFill />{" "}
                          {userInfo.phone ? (
                            formatPhoneNumber(userInfo.phone)
                          ) : (
                            <span className="placeholder_text">
                              Phone / Mobile Number
                            </span>
                          )}
                        </p>
                      </div>

                      <div className="col-12 col-md-6">
                        <p>
                          <FaBirthdayCake />{" "}
                          {userInfo.birthday ? (
                            userInfo.birthday.split("-").join("/")
                          ) : (
                            <span className="placeholder_text">
                              Your Birthdate
                            </span>
                          )}
                        </p>
                      </div>

                      <div className="col-12 col-md-6">
                        <p>
                          <ImHappy2 />{" "}
                          {userInfo.interested_in ? (
                            userInfo.interested_in
                          ) : (
                            <span className="placeholder_text">
                              Your Interest
                            </span>
                          )}
                        </p>
                      </div>

                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <p>
                              <MdHomeWork />{" "}
                              {userInfo.industry ? (
                                userInfo.industry
                              ) : (
                                <span className="placeholder_text">
                                  Industy
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="col-12 col-md-6">
                            <p>
                              <BsStars />{" "}
                              {userInfo.classification ? (
                                userInfo.classification
                              ) : (
                                <span className="placeholder_text">
                                  Classifications
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </IconContext.Provider>
                  </div>
                </div>

                <div className="my-profile-about-box">
                  <div className="row">
                    <div className="col-12">
                      <h3 className="h4">About</h3>

                      <p className="m-0">
                        {userInfo.about ? (
                          userInfo.about
                        ) : (
                          <span className="placeholder_text text-mute">
                            Write about your years of experience, industry, or
                            skills. People also talk about their achievements or
                            previous job experiences.
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <ConnectionsWidget
                  connection={userInfo.connections}
                  widgetTitle="My Connections"
                  limit="5"
                  morelink="/my-connections"
                  type="link"
                  icontype="person"
                />
              </div>
            </div>
          </div>
        </MainContainer>
        {this.state.EditwindowVisible && (
          <MyProfilePopup
            Data={userInfo}
            sendDataToParent={this.sendDataToParent}
            EditwindowVisible={this.state.EditwindowVisible}
          />
        )}

        {this.state.deleteProfile && (
          <Modal isOpen={this.state.deleteProfile}>
            <ModalHeader>
              <h5>Confirmation</h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.toggleDeleteConfirmationWindow}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody>
              <p>Are you sure would like to delete your profile?</p>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-primary rounded-0 px-4"
                onClick={() =>
                  this.handleDeleteProfile(
                    userInfo?.userId,
                    userInfo?.connections,
                    userInfo?.pending_connections
                  )
                }
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary rounded-0 px-4"
                onClick={this.toggleDeleteConfirmationWindow}
              >
                Cancel
              </button>
            </ModalFooter>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
});

const mapDispatchToProps = (dispatch) => {
  return({
    setcurrentUser: (user) => dispatch(setCurrentUser(user))
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);

