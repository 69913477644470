import React, { useEffect, useState } from "react";
import "./login-signup.style.scss";

// Components
import Login from "./login/login.components";
import SignUp from "./signup/signup.components";
import Logo from "../../components/logo/logo.components";

const LoginSignUp = () => {
  const [registerVisible, setRegisterVisible] = useState(false);
  const [userEmail, setUserEmail] = useState("")

  const sendDataToParent = (visiblebool) => {
    setRegisterVisible(visiblebool);
  };

  useEffect( () => {
    let paramsString = window.location.search;
    let searchParams = new URLSearchParams(paramsString);
    if(searchParams.has('type') || searchParams.has('email')) {
      if (searchParams.get("type") === "signUp") {
        setRegisterVisible(true);
      }
      if (searchParams.get("email")) {
        setUserEmail(searchParams.get("email"));
      }
    }
  }, [])

  return (
    <main className="login-signup-container">
      <article className="conatiner-fluid h-100">
        <div className="container h-100 d-flex align-items-center">
          <div className="row h-md-100 align-items-center px-0 px-sm-4">
            <div className="col-12 col-md-12 col-lg-6 offset-lg-3 mb-0 text-center">
              <Logo className="mb-2 mb-md-3" />
              <p className="h5 login-tagline ">
                3For3 Networking finds you new professional friends, mentors and
                life long connections.
              </p>
            </div>
            <div className="col-12 col-md-12 col-lg-6 offset-lg-3">
              <Login
                registerVisible={registerVisible}
                sendDataToParent={sendDataToParent}
              />
            </div>
            {registerVisible ? (
              <SignUp
                registerVisible={registerVisible}
                sendDataToParent={sendDataToParent}
                useremail={userEmail}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </article>
    </main>
  );
};

export default LoginSignUp;
