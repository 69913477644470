import React, { useEffect, useState } from "react";
import axios from "axios";
import clientConfig from "../../env";
import Logo from "../../components/logo/logo.components";
import UserProfilePopup from "../../components/user-profile-popup/user-profile-popup";

const styles = {
  callScheduleContainer: {
    minHeight: "100vh",
    backgroundColor: "#212529",
    color: "#ffffff",
  },
};

const RecommendedMeeting = () => {
  const [requestType, setRequestType] = useState(null);
  const [username, setUsername] = useState();
  const [profileData, setProfileData] = useState();

  const rescheduleGetUserInfo = (values) => {
    const data = { ...values, request_type: "recommended" };

    axios
      .get(`${clientConfig.siteUrl}wl/v1/user/${data.connectwith_user_id}`)
      .then((response) => {
        if (response.status === 200) {
          setProfileData({
            ...response.data,
            sender_id: data?.user_id,
          });
          setUsername(
            `${response?.data?.first_name} ${response?.data?.last_name}`
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    setRequestType(params.req);

    console.log(params);

    if (params.req === "recommended" && params.sc) {
      const values = JSON.parse(window.atob(params.sc));
      console.log(values)
      rescheduleGetUserInfo(values);
    }
  }, []);

  return (
    <>
      <div style={styles.callScheduleContainer} className="py-3  py-md-5">
        <div className="py-4 text-center">
          <Logo />
        </div>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              {requestType === "recommended" && (
                <Schedule name={username} profileData={profileData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecommendedMeeting;

const Schedule = ({ name, profileData }) => {
  return (
    <>
      {name ? (
        <h2 className="text-center w-100 mb-4">Schedule Call with {name}</h2>
      ) : null}

      {profileData ? (
        <UserProfilePopup
          profile={profileData}
          popupVisible={true}
          nextbtnvisible={false}
          titleVisible={false}
          otherClassName="static_reschedule_popup"
          showCancelButton={false}
        />
      ) : null}
    </>
  );
};
