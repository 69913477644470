import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import axios from 'axios';
import clientConfig from '../../env';
import useLatestUserData from '../../utilities/useLatestUserData';
import "./manage-preferences.styles.scss";

//constants
import { RECOMMENDEDMATCH } from "../../constants/recommendedMatch";

//components
import MainContainer from '../../components/main-container/main-container.component';
import { setCurrentUser } from '../../redux/user/user.actions';

const notificationNote = (
  <div className="row px-3">
    <div className="col-12">
      <div className="alert alert-info" role="alert">
        Note: You are currently enrolled in the directory.
      </div>
    </div>
  </div>
)

function ManagePreferences() {

    const dispatch = useDispatch()
    const useInfo = useSelector( (state) => state.user)
    const userId = useSelector((state) => state.user.userId);
    const token = useSelector((state) => state.user.token);
    const preferenceSettings = useSelector(
      (state) => state.user.preferencesSettings
    );
    const recommendedMatchSetting = useSelector(
      (state) => state.user.recommendedMatch ? state.user.recommendedMatch : RECOMMENDEDMATCH.WEEKLY_CONNECTION_MATCH
    );
    const emailVerified = useSelector((state) => state.user.email_verified);
    const currentStatus = useSelector((state) => state.user.status);

    const[notificationType, setNotificationType] = useState("all");
    const [removedMe, setRemovedMe] = useState(false);
    const [recommendedMatch, setRecommendedMatch] = useState(recommendedMatchSetting);

    const changeNotification = useCallback((value) => {
      setNotificationType(value);
      handleUpdatePreference(userId, {preference: value, recommendedMatch});
      if (value === "nonotification" && currentStatus !== "Inactive") {
        handleChangeDirectory({ target: { checked: true } });
      } 
      
      if (value !== "nonotification" && currentStatus === "Inactive" && currentStatus !== "Active") {
        handleChangeDirectory({ target: { checked: false } });
      }
    }, [notificationType]);

    useEffect(() => {
      if (!emailVerified || emailVerified === null) {
        setNotificationType("nonotification");
        setRecommendedMatch(RECOMMENDEDMATCH.NO_RECOMMENDED_MATCH);
      } else {
        setNotificationType(preferenceSettings);
        setRecommendedMatch(recommendedMatchSetting);
      }
      // if(preferenceSettings === "nonotification") {
      //   handleChangeDirectory({ target: { checked: true } });
      // }
    }, [preferenceSettings, emailVerified, recommendedMatchSetting]);

    const fetchLatestUserData = useLatestUserData();

    useEffect(() => {
      if (
        (!emailVerified || emailVerified === null) &&
        currentStatus === "Active"
      ) {
        setRemovedMe(true);
      } else if (currentStatus === "Active") {
        setRemovedMe(false);
      } else {
        setRemovedMe(true);
      }
    }, [currentStatus]);

    const handleChangeDirectory = useCallback(
      (e) => {
        setRemovedMe(e.target.checked);
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Barer ${token}`,
        };
        const payloadData = {
          userId: userId,
          status: e.target.checked ? "Inactive" : "Active",
        };

        axios
          .put(
            `${clientConfig.siteUrl}wl/v1/remove-from-directory`,
            payloadData,
            {
              headers: headers,
            }
          )
          .then((response) => {
            console.log({ response });
            if (response.status === 200) {
              toast.success(response.data.message);
              dispatch(
                setCurrentUser({ ...useInfo, status: e.target.checked ? "Inactive" : "Active"})
              );
              fetchLatestUserData({ id: userId, token: token });
            } else {
              toast.error(response.data.error);
            }
          })
          .catch((error) => console.error(error));
      },
      [removedMe]
    );

    const handleUpdatePreference = useCallback(
      async (userId, value) => {
        const preferencePayload = {
          user_id: userId,
          preferences: value.preference,
          recommendedMatch:
            value.preference === "nonotification"
              ? RECOMMENDEDMATCH.NO_RECOMMENDED_MATCH
              : value.recommendedMatch,
        };

        const headers = {
          "Content-Type": "application/json",
          Authorization: `Barer ${token}`,
        };

        const response = await axios.post(
          `${clientConfig.siteUrl}wl/v1/preferences-settings`,
          preferencePayload,
          {
            headers: headers,
          }
        );

        if (response.status === 200 && response.data.updated) {
          if (value.preference === "nonotification") {
            setRecommendedMatch(RECOMMENDEDMATCH.NO_RECOMMENDED_MATCH)
          }
            toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      },
      []
    ); 

    const handleRecommendedMatch = (event) => {
      setRecommendedMatch(event.target.value);
      handleUpdatePreference(userId, {
        preference: notificationType,
        recommendedMatch: event.target.value,
      });
    }
      

    const handleClickRemoveDirectory = (e) => {
      e.preventDefault()
      if(removedMe) {
        handleChangeDirectory({ target: { checked: false } });
      } else {
        handleChangeDirectory({ target: { checked: true } });
      }
    }

  return (
    <MainContainer>
      <div className="preferences-container py-4">
        <h1 className="title">Preferences</h1>
        <div className="row">
          <div className="col-12 col-lg-6 mt-4 p-0">
            <h2 className="px-3 subtitle">
              Tell us what you'd like to receive:
            </h2>

            <div
              className={`row border p-3 m-3 rounded mb-2 preference-item ${
                notificationType === "all" ? "active" : null
              }`}
              onClick={
                !emailVerified ? () => {} : () => changeNotification("all")
              }
            >
              <div className="col-1">
                <input
                  name="notification"
                  value="all"
                  className="mt-2"
                  type="radio"
                  aria-label="Receive All Notifications."
                  checked={notificationType === "all" ? true : false}
                  readOnly={true}
                  disabled={!emailVerified}
                />
              </div>
              <div className="col-11">
                <p className="lead mb-0 title">Receive All Notifications.</p>
                <p className="text-muted mb-0 description">
                  Including hints and tips and discussion digests.
                </p>
              </div>
            </div>

            {notificationType === "all" && currentStatus !== "Inactive"
              ? notificationNote
              : null}

            <div
              className={`row border p-3 m-3 rounded mb-2 preference-item ${
                notificationType === "important" ? "active" : null
              }`}
              onClick={
                !emailVerified ? () => {} : () => changeNotification("important")
              }
            >
              <div className="col-1">
                <input
                  name="notification"
                  className="mt-2"
                  type="radio"
                  aria-label="Receive Important Notifications."
                  checked={notificationType === "important" ? true : false}
                  readOnly={true}
                  disabled={!emailVerified}
                />
              </div>
              <div className="col-11">
                <p className="lead mb-0 title">
                  Receive Important Notifications.
                </p>
                <p className="text-muted mb-0 description">
                  Only receive notifications such as connection requests,
                  recommendations, personal messages and community newsletters.
                </p>
              </div>
            </div>

            {notificationType === "important" && currentStatus !== "Inactive"
              ? notificationNote
              : null}

            <div
              className={`row border p-3 m-3 rounded mb-2 preference-item ${
                notificationType === "nonotification" ? "active" : null
              }`}
              onClick={
                !emailVerified
                  ? () => {}
                  : () => changeNotification("nonotification")
              }
            >
              <div className="col-1">
                <input
                  name="notification"
                  className="mt-2"
                  type="radio"
                  aria-label="Receive No Notifications."
                  checked={notificationType === "nonotification" ? true : false}
                  readOnly={true}
                  disabled={!emailVerified}
                />
              </div>
              <div className="col-11">
                <p className="lead mb-0 title">Receive No Notifications.</p>
                <p className="text-muted mb-0 description">
                  Only receive updates about your account, privacy, and
                  security.
                </p>
              </div>
            </div>

            {notificationType === "nonotification" && (
              <div className="row px-3">
                <div className="col-12">
                  <div className="alert alert-danger" role="alert">
                    Warning: This will remove you from the directory and users
                    will no longer be able to connect with you.
                  </div>
                </div>
              </div>
            )}

            <div className="row border-0 p-3 m-3 rounded mb-2">
              <div className="col-1">
                <input
                  type="checkbox"
                  className="form-check-input position-relative ml-0 mt-2"
                  id="remove_from_directory"
                  onChange={
                    notificationType === "nonotification"
                      ? () => {}
                      : (e) => handleChangeDirectory(e)
                  }
                  checked={removedMe}
                  disabled={
                    !emailVerified ||
                    emailVerified === null ||
                    notificationType === "nonotification"
                  }
                />
              </div>
              <div className="col-11">
                <label
                  htmlFor="remove_from_directory mb-0"
                  style={{ fontSize: 18 }}
                  onClick={
                    notificationType === "nonotification"
                      ? () => {}
                      : handleClickRemoveDirectory
                  }
                >
                  Remove My Profile From Directory
                </label>
                <p className="mb-0 description">
                  <small style={{ color: "#3861b3" }}>
                    <strong>Note:</strong> If you enable this feature your
                    profile card will not be available in the directory.
                  </small>
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 mt-4 p-0">
            <h2 className="px-3 subtitle">Email Settings</h2>

            <div className="row border-0 m-3 rounded mb-2">
              <label>Recommended Match</label>
              <select
                className="form-control"
                onChange={handleRecommendedMatch}
                value={recommendedMatch}
                disabled={notificationType === "nonotification"}
              >
                <option value={RECOMMENDEDMATCH.NO_RECOMMENDED_MATCH}>
                  No notifications
                </option>
                <option
                  value={RECOMMENDEDMATCH.DAILY_CONNECTION_MATCH}
                  disabled
                >
                  Daily connection
                </option>
                <option value={RECOMMENDEDMATCH.WEEKLY_CONNECTION_MATCH}>
                  Weekly connection
                </option>
                <option
                  value={RECOMMENDEDMATCH.MONTHLY_CONNECTION_MATCH}
                >
                  Monthly connection
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}

export default ManagePreferences