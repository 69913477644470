import React from "react";
import "./questions.styles.scss";

class Questions extends React.Component {

  constructor(){
    super();
    this.state = {
      faqs: [
        {
          question: "What is 3FOR3 Networking?",
          answer:
            "3FOR3 Networking is a business mentorship and networking group. We have designed the platform for professionals, business owners and students to build and network through digital technology. This platform will grow your business networking group to increase sales, grow your inner circle and even find a mentor.",
        },
        {
          question: "Why Join the 3FOR3 Network?",
          answer:
            "Business networking is something that can help you reach more connections, knowledge, and eventually attain business growth and increase your profits. 3FOR3 Networking will allow you to do all of this.",
        },
        {
          question: "How does 3FOR3 Networking Work?",
          answer:
            "Share your business and life successes and mistakes with other business owners and industry professionals. It is extremely simple to get started! First, schedule a call, then engage on the call and thats it! Our algorithm will do the follow ups and continue to remind you and your connections to continue building the relationships.",
        },
        {
          question: "What types of communication can you setup?",
          answer:
            "Currently we have a digital meeting room where you can meet and have both audio/video or only audio if you prefer. We highly recommend you to connect in-person after 1-2 digital meetings if you are local to each other.",
        },
        {
          question: "How can this be free?",
          answer:
            "Get started with one of America's Fastest growing digital business networks! We built the free platform to connect others and build networks through digital technology. We offer paid options if you want to fully take advantage of the networking power.",
        },
        {
          question: "What are the benefits to join this networking group?",
          answer: `<ul class="pl-4">
          <li>It's FREE</li>
          <li>Stay on the cutting edge of your field.</li>
          <li>Find people who you can mentor.</li>
          <li>Find people that can be your mentor.</li>
          <li>Have customers sent your way.</li>
          <li>Build network credibility.</li>
          <li>Contribute to industry/community.</li>
          </ul>`,
        },
      ],
      loading: false,
    };
  }

  render() {

    const { loading, faqs } = this.state;

    return(
      <div className="faq-questions-container">
        <div className="accordion" id="faqs">
          { loading ? (<h3>Loading...</h3>) : 
          (
            faqs.map( ({question, answer}, key) => (
              <div className="card" key={key}>
                <div className="card-header" id={`heading${+key + 1}`}>
                  <h2 className="mb-0">
                    <button
                    className="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#collapse${+key + 1}`}
                    aria-expanded={ +key+1 === 1 ? 'true' : 'false' }
                    aria-controls={`collapse${+key + 1}`}
                    >{+key + 1}. {question}</button>
                  </h2>
                </div>
                <div
                  id={`collapse${+key + 1}`}
                  className={`collapse ${ +key+1 === 1 ? 'show' : '' }`}
                  aria-labelledby={`heading${+key + 1}`}
                  data-parent="#faqs"
                >
                  <div className="card-body" dangerouslySetInnerHTML={{__html:answer}}></div>
                </div>
              </div>
            ))
          )
          }
        </div>
      </div>
    );
  }

}

export default Questions;
