import React from 'react';
import { useSelector } from 'react-redux';
import MainContainer from "../../components/main-container/main-container.component";

import ConnectionsWidget from '../../components/connections/connections.component';
import { Link } from 'react-router-dom';

function MyConnections() {

    const myConnection = useSelector(state => state.user.connections);

    return (
      <MainContainer>
          <div className="connections-container py-4">
              <div className="row">
                  <div className="col-12 col-md-12">
                        <div className='row'>
                            <div className='col-sm-6'>
                                <h1 className="title mb-4">My Connections</h1>
                            </div>
                            <div className='col-sm-6 text-md-right'>
                                <Link to="/connections" className='btn btn-md btn-outline-secondary px-4 rounded-0 btn-getmorecredit'>
                                    Back
                                </Link>
                            </div>
                        </div>

                        <ConnectionsWidget connection={ Array.isArray(myConnection) ? myConnection : []} type="link" icontype="person" pagetype="grid" />
                  </div>
              </div>
          </div>
      </MainContainer>
    )
}

export default MyConnections;