import React, { useRef } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

// Common Components
import Footer from "../footer/footer.components";
import Header from "../header/header.components";
import SidebarMenu from "../sidebar-menu/sidebar-menu.components";
import { useEffect } from "react";

import ScheduleCallBtn from "../schedule-call-btn/schedule-call-btn.component";
import { useState } from "react";
import moment from "moment";

const MainContainer = (props) => {

  const history = useHistory();
  const containerRef = useRef(null);
  const [triggerEvent, setTriggerEvent] = useState(false);
  const [sidebarMenuOpen, setSidebarMenuOpen] = useState(false);

  const parentFunction = () => {
    setTriggerEvent(true);
  }

  const popupcloseGetData = (value) => {
    setTriggerEvent(value);
    if (props.popupVisible) {
      props.callSchedulefromParent();
    }
  }

  const sidebarMenuOpenParent = (value) => {
    setSidebarMenuOpen(value);
  }

  useEffect( () => {
    moment.updateLocale("en", {
      week: {
        // Set the First day of week to Sunday
        dow: 0,
      },
    });
  }, [])

  useEffect(() => {
    containerRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }, [history.location.pathname]);

  useEffect( () => {
    if (props.popupVisible) {
      parentFunction();
    }
  }, [props.popupVisible])
  

  return (
    <main ref={containerRef} style={{ height: '100vh', overflowX: 'hidden', position: 'relative'}}>
      <Header
        parentFunction={parentFunction}
        sidebarMenuOpenParent={sidebarMenuOpenParent}
      />
      <article className="container-fluid">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="row">
          <SidebarMenu
            sidebarMenuOpen={sidebarMenuOpen}
            sidebarMenuOpenParent={sidebarMenuOpenParent}
          />
          <div className="main-container col-md-9 col-lg-10">
            {/* <div className="alert alert-warning mt-2 mx-3" role="alert">
              Your calendar is not connected.
            </div> */}
            <ScheduleCallBtn
              triggerEvent={triggerEvent}
              popupcloseGetData={popupcloseGetData}
            />
            {props.children}
          </div>
        </div>
      </article>
      <Footer />
    </main>
  );
}

export default MainContainer;
