import React from "react";
import { Link } from "react-router-dom";
import "./connections.styles.scss";
import axios from "axios";

import { ReactComponent as Logo } from "../../logo.svg";

//icons
import { BsArrowRightCircleFill } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";
import { IoIosCheckmarkCircle, IoIosEye } from "react-icons/io";
import { AiOutlineSchedule } from "react-icons/ai";
import { MdCancel, MdCheckCircle, MdOutlineCancel } from "react-icons/md";
import clientConfig from "../../env";
import CustomTooltip from "../toolTips/tooltips.components";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../modal/modal.components";
import { checkDateIsPast } from "../../utilities/utilities";
import { connect } from "react-redux";
import { toast } from "react-toastify";

class ConnectionsWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connection: this.props.connection,
      connectionList: null,
      loading: false,
      selectedConfirmationId: null,
      selectedConfirmationDetail: null,
    };
  }

  componentDidMount() {
    if (this.state.connection) {
      this.getMyConnectionData(this.state.connection);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.connection !== prevProps.connection) {
      this.getMyConnectionData(this.props.connection);
    }
  }

  getMyConnectionData = (array) => {
    if (Array.isArray(array) && array?.length) {
      let itemlist = [];
      const sortedActivities = array?.sort(
        (a, b) => new Date(b?.date) - new Date(a?.date)
      );
      sortedActivities?.map((item, index) => {
        return axios
          .get(`${clientConfig.siteUrl}wl/v1/user/${item?.id || item}`)
          .then((response) => {
            if (response.status === 200) {
              item?.call_scheduled
                ? itemlist.push({
                    ...response.data,
                    order_no: index,
                    call_scheduled: item?.call_scheduled,
                    userStatus: item?.status,
                    ...item,
                  })
                : itemlist.push({
                    ...response.data,
                    order_no: index,
                    userStatus: item?.status,
                    ...item,
                  });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            if (array?.length === itemlist?.length) {
              this.setState({
                connectionList: itemlist?.sort(
                  (a, b) => a.order_no - b.order_no
                ),
              });
            } else {
              this.setState({
                loading: true,
              });
            }
          });
      });
    }
  };

  handlePopupOpen = (id, scheduled = false) => {
    axios
      .get(`${clientConfig.siteUrl}wl/v1/user/${id}`)
      .then((response) => {
        if (response.status === 200) {
          this.props.getDatapopupfromChild({
            ...response.data,
            call_scheduled: scheduled,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getdatafromChidcompo = (id, scheduled) => {
    this.handlePopupOpen(id, scheduled);
  };

  handleCallrequest = (user_id, connectwith_id, request_type, meeting_id) => {
    const data = {
      user_id: user_id,
      connectwith_user_id: connectwith_id,
      request_type: request_type,
      meeting_id: meeting_id,
    };
    const headers = {
      "Content-Type": "application/json",
    };

    console.log(" UI connect data ", data)

    axios
      .post(`${clientConfig.siteUrl}wl/v1/aftercall-request-pending`, data, {
        headers: headers,
      })
      .then((response) => {
        this.setState((prev) => {
          return {
            ...prev,
            selectedConfirmationId: null,
            selectedConfirmationDetail: null,
          };
        });

        console.log("connection", response);
        if (response.status === 200) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        document.getElementById("yesconnection_close_btn").click();
        document.getElementById("noconnection_close_btn").click();
      })
      .catch((error) => {
        console.error(error)
        this.setState((prev) => {
          return {
            ...prev,
            selectedConfirmationId: null,
            selectedConfirmationDetail: null,
          };
        });
      });
  };

  // Remove items from pending list
  handleRemoveItemsfromList = async (userId, connectwith_id, meeting_id, meeting_date, closebtn_id) => {
    try {
      const data = {
        user_id: userId,
        connectwith_user_id: connectwith_id,
        meeting_id: meeting_id,
        meeting_date: meeting_date
      };
      const headers = {
        "Content-Type": "application/json",
      };
  
      const response = await axios.post(
        `${clientConfig.siteUrl}wl/v1/remove-user-mypendingconnection`,
        data,
        {
          headers: headers,
        }
      );
  
      if(response.status === 200) {
        toast.success(response.data.message);
        document.getElementById(closebtn_id).click();
      } else {
        toast.error(response.data.message);
        document.getElementById(closebtn_id).click();
      }
    } catch(error) {
      console.log("someting went wrong")
      console.error(error);
    }
  }

  openConfirmationPopup = (data) => {
    this.setState((prev) => {
      return {
        ...prev,
        selectedConfirmationId: data?.modalId,
        selectedConfirmationDetail: {
          userId: this.props.userId,
          connectwith_id: data?.connectwith_id,
          fullName: data?.fullName,
          requestType:
            data?.modalId === "confirmationNoDecline"
              ? "no_decline"
              : "yes_confirm",
          meeting_date: data?.meeting_date || null,
          meeting_id: data?.meeting_id || null,
        },
      };
    });
  };

  toggleConfirmationWindow = () => {
    this.setState((prev) => {
      return {
        ...prev,
        selectedConfirmationId: null,
        selectedConfirmationDetail: null,
      };
    });
  }

  render() {
    const { widgetTitle, limit, type, morelink, icontype, pagetype } =
      this.props;
    const { connectionList, loading, selectedConfirmationDetail } =
      this.state;
    return (
      <React.Fragment>
        <div
          className={`connections-list-container${
            pagetype === "grid" ? " grid-layout" : ""
          }`}
        >
          {widgetTitle && (
            <div className="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
              <h3 className="h6 m-0">{widgetTitle}</h3>
              {limit && morelink ? (
                <Link
                  className="btn btn-transparent p-0 btn-viewall"
                  to={morelink}
                >
                  View All
                </Link>
              ) : (
                ""
              )}
            </div>
          )}

          <div
            className={`list-group list-group-flush${
              pagetype === "grid" ? " row flex-row px-3" : ""
            }`}
          >
            {!connectionList ? (
              loading ? (
                <div className="d-flex align-items-center">
                  <span>Fetching records...</span>
                  <div
                    className="spinner-border spinner-border-md ml-auto"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>
              ) : (
                <p className="m-0">No Connections</p>
              )
            ) : (
              connectionList.map((item, index) => {
                return pagetype === "grid" ? (
                  <div
                    key={`listitem_${index}_${widgetTitle}`}
                    className="col-12 col-md-4 py-1 px-1"
                  >
                    <ListItem
                      item={{ ...item, current_user_id: this.props?.userId }}
                      index={index}
                      type={type}
                      icontype={icontype}
                      handlePopupOpen={(id, call_scheduled) =>
                        this.getdatafromChidcompo(id, call_scheduled)
                      }
                      onclickIconConnection={this.handleCallrequest}
                      openConfirmationPopup={this.openConfirmationPopup}
                      widget={widgetTitle}
                    />
                  </div>
                ) : (
                  <ListItem
                    key={`listitem_${index}_${widgetTitle}`}
                    item={{ ...item, current_user_id: this.props?.userId }}
                    index
                    type={type}
                    icontype={icontype}
                    handlePopupOpen={(id, call_scheduled) =>
                      this.getdatafromChidcompo(id, call_scheduled)
                    }
                    onclickIconConnection={this.handleCallrequest}
                    openConfirmationPopup={this.openConfirmationPopup}
                    widget={widgetTitle}
                  />
                );
              })
            )}
          </div>
        </div>
        {this.state.selectedConfirmationId === "confirmationYesConnect" && (
          <>
            <Modal isOpen={true}>
              <ModalHeader>
                <h5>Confirmation</h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.toggleConfirmationWindow}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </ModalHeader>
              <ModalBody>
                <p className="m-0">
                  Would you like to add{" "}
                  <b>{selectedConfirmationDetail?.fullName}</b> to your
                  connections?
                </p>
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  className="btn btn-primary rounded-0 px-4"
                  onClick={() =>
                    this.handleCallrequest(
                      selectedConfirmationDetail?.userId,
                      selectedConfirmationDetail?.connectwith_id,
                      selectedConfirmationDetail?.requestType,
                      selectedConfirmationDetail?.meeting_id
                    )
                  }
                >
                  Yes, Connect
                </button>
                <button
                  id="yesconnection_close_btn"
                  type="button"
                  className="btn btn-outline-secondary rounded-0 px-4"
                  onClick={this.toggleConfirmationWindow}
                >
                  Cancel
                </button>
              </ModalFooter>
            </Modal>
          </>
        )}

        {this.state.selectedConfirmationId === "confirmationNoDecline" && (
          <>
            <Modal isOpen={true}>
              <ModalHeader>
                <h5>Confirmation</h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.toggleConfirmationWindow}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </ModalHeader>
              <ModalBody>
                <p className="m-0">
                  If you decline the connection,{" "}
                  <b>{selectedConfirmationDetail?.fullName}</b> will be removed
                  from your list.
                </p>
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  className="btn btn-primary rounded-0 px-4"
                  onClick={() =>
                    this.handleCallrequest(
                      selectedConfirmationDetail?.userId,
                      selectedConfirmationDetail?.connectwith_id,
                      selectedConfirmationDetail?.requestType,
                      selectedConfirmationDetail?.meeting_id
                    )
                  }
                >
                  Decline Connection
                </button>
                <button
                  id="noconnection_close_btn"
                  type="button"
                  className="btn btn-outline-secondary rounded-0 px-4"
                  onClick={this.toggleConfirmationWindow}
                >
                  Cancel
                </button>
              </ModalFooter>
            </Modal>
          </>
        )}

        {this.state.selectedConfirmationId === "confirmationMeetingExpired" && (
          <>
            <Modal isOpen={true}>
              <ModalHeader>
                <h5>Confirmation</h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.toggleConfirmationWindow}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </ModalHeader>
              <ModalBody>
                <p className="m-0">
                  Meeting Expired. You can remove this notification by clicking
                  below.
                </p>
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  className="btn btn-primary rounded-0 px-4"
                  onClick={() =>
                    this.handleRemoveItemsfromList(
                      selectedConfirmationDetail?.userId,
                      selectedConfirmationDetail?.connectwith_id,
                      selectedConfirmationDetail?.meeting_id,
                      selectedConfirmationDetail?.meeting_date,
                      "meetingexpired_close_btn"
                    )
                  }
                >
                  Clear Notification
                </button>
                <button
                  id="meetingexpired_close_btn"
                  type="button"
                  className="btn btn-outline-secondary rounded-0 px-4"
                  onClick={this.toggleConfirmationWindow}
                >
                  Cancel
                </button>
              </ModalFooter>
            </Modal>
          </>
        )}

        {this.state.selectedConfirmationId ===
          "confirmationMeetingCompleted" && (
          <>
            <Modal isOpen={true}>
              <ModalHeader>
                <h5>Confirmation</h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.toggleConfirmationWindow}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </ModalHeader>
              <ModalBody>
                <p className="m-0">
                  Meeting Completed. You can remove this notification by
                  clicking below.
                </p>
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  className="btn btn-primary rounded-0 px-4"
                  onClick={() =>
                    this.handleRemoveItemsfromList(
                      selectedConfirmationDetail?.userId,
                      selectedConfirmationDetail?.connectwith_id,
                      selectedConfirmationDetail?.meeting_id,
                      selectedConfirmationDetail?.meeting_date,
                      "meetingcomplete_close_btn"
                    )
                  }
                >
                  Clear Notification
                </button>
                <button
                  id="meetingcomplete_close_btn"
                  type="button"
                  className="btn btn-outline-secondary rounded-0 px-4"
                  onClick={this.toggleConfirmationWindow}
                >
                  Cancel
                </button>
              </ModalFooter>
            </Modal>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ userId: state.user.userId });

export default connect(mapStateToProps)(ConnectionsWidget);

const connectionIcon = (item, openConfirmationPopup, onclickIconConnection) => {
  if (item?.userStatus && item?.userStatus === "active") {
    if (item?.is_in_connection) {
      return (
        <div className="d-flex gap-3 flex-end">
          <CustomTooltip title="Confirmed connection.">
            <FaRegUser size={"1.2em"} className="mx-1" />
          </CustomTooltip>
        </div>
      );
    }

    if (
      item?.after_call_connection_request?.accept ||
      item?.after_call_connection_request?.decline
    ) {
      if (item?.after_call_connection_request?.status) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <CustomTooltip title="Awaiting confirmation from other user.">
              <span style={{ fontSize: 15, color: "#3861b3", fontWeight: 700 }}>
                PENDING
              </span>
            </CustomTooltip>
            <CustomTooltip title="Decline connection request.">
              <button
                style={{ fontSize: 12, color: "#fd1d1d" }}
                className="p-0 border-0 bg-transparent"
                data-toggle="modal"
                data-target="#confirmationNoDecline"
                onClick={() =>
                  openConfirmationPopup({
                    userId: item?.current_user_id,
                    connectwith_id: item?.id,
                    modalId: "confirmationNoDecline",
                    fullName: `${item.first_name} ${item?.last_name}`,
                    meeting_id: item?.meeting_id
                  })
                }
              >
                Cancel
              </button>
            </CustomTooltip>
          </div>
        );
      } else {
        return (
          <div className="d-flex gap-3 flex-end">
            <CustomTooltip title="Yes, confirm connection.">
              <button
                className="p-0 border-0 bg-transparent mx-1"
                data-toggle="modal"
                data-target="#confirmationYesConnect"
                onClick={() =>
                  openConfirmationPopup({
                    userId: item?.current_user_id,
                    connectwith_id: item?.id,
                    modalId: "confirmationYesConnect",
                    fullName: `${item.first_name} ${item?.last_name}`,
                    meeting_id: item?.meeting_id,
                  })
                }
              >
                <MdCheckCircle
                  color="green"
                  size={"1.4em"}
                  className="mx-0"
                  cursor={"pointer"}
                />
              </button>
            </CustomTooltip>
            <CustomTooltip title="No, decline connection.">
              <button
                className="p-0 border-0 bg-transparent mx-1"
                data-toggle="modal"
                data-target="#confirmationNoDecline"
                onClick={() =>
                  openConfirmationPopup({
                    userId: item?.current_user_id,
                    connectwith_id: item?.id,
                    modalId: "confirmationNoDecline",
                    fullName: `${item.first_name} ${item?.last_name}`,
                    meeting_id: item?.meeting_id,
                  })
                }
              >
                <MdOutlineCancel
                  size={"1.4em"}
                  className="mx-0"
                  cursor={"pointer"}
                />
              </button>
            </CustomTooltip>
          </div>
        );
      }
    }

    if (
      item?.call_scheduled &&
      item?.call_request_accept &&
      checkDateIsPast(item?.meeting_date)
    ) {
      return (
        <CustomTooltip title="Meeting Completed.">
          <button
            className="p-0 border-0 bg-transparent"
            data-toggle="modal"
            data-target="#confirmationMeetingCompleted"
            onClick={() =>
              openConfirmationPopup({
                userId: item?.current_user_id,
                connectwith_id: item?.id,
                modalId: "confirmationMeetingCompleted",
                fullName: `${item.first_name} ${item?.last_name}`,
                meeting_date: item.meeting_date,
                meeting_id: item.meeting_id
              })
            }
          >
            <IoIosCheckmarkCircle color="green" size={"1.5em"} />
          </button>
        </CustomTooltip>
      );
    }

    if (item?.call_scheduled && item?.call_request_accept) {
      return (
        <CustomTooltip title="Confirmed meeting by both parties.">
          <AiOutlineSchedule color="green" size={"1.5em"} />
        </CustomTooltip>
      );
    }

    if (
      item?.call_scheduled &&
      !item?.call_request_accept &&
      checkDateIsPast(item?.meeting_date)
    ) {
      return (
        <CustomTooltip title="Meeting Expired.">
          <button
            className="p-0 border-0 bg-transparent"
            data-toggle="modal"
            data-target="#confirmationMeetingExpired"
            onClick={() =>
              openConfirmationPopup({
                userId: item?.current_user_id,
                connectwith_id: item?.id,
                modalId: "confirmationMeetingExpired",
                fullName: `${item.first_name} ${item?.last_name}`,
                meeting_date: item.meeting_date,
                meeting_id: item.meeting_id,
              })
            }
          >
            <MdCancel color="#fd1d1d" size={"1.4em"} />
          </button>
        </CustomTooltip>
      );
    }

    if (item?.call_scheduled && !item?.call_request_accept) {
      return (
        <CustomTooltip title="Meeting scheduled.">
          <BsArrowRightCircleFill size={"1.4em"} />
        </CustomTooltip>
      );
    }

    if (item?.recently_viewed && !item?.call_scheduled) {
      return (
        <CustomTooltip title="Recently viewed.">
          <IoIosEye size={"1.4em"} />
        </CustomTooltip>
      );
    }
  }
};

function ListItem({
  item,
  index,
  type,
  icontype,
  handlePopupOpen,
  onclickIconConnection,
  openConfirmationPopup,
  widget
}) {
  return item?.status?.toLowerCase() == "active" ? (
    <div className="list-group-item list-group-item-action px-2">
      <a
        href={type === "link" ? `/p/${item.profile_unique_key}` : "#"}
        key={`item_${index}_${item.id}`}
        onClick={() => {
          type === "popup" && handlePopupOpen(item.id, item.call_scheduled);
        }}
      >
        <div className="media" data-id={item.id}>
          {item.profile_picture ? (
            <img
              src={item.profile_picture}
              className="img-fluid mr-3 rounded-circle thumb"
              alt={item.first_name}
            />
          ) : (
            <Logo className="mr-3 rounded-circle thumb" />
          )}
          <div className="media-body">
            <div className="d-flex w-100 align-items-center justify-content-between">
              <div className="mb-0 h6">
                {item.first_name} {item.last_name}
              </div>
            </div>
            <small className="mb-1 d-block">{item.interested_in}</small>
            {item.country && (
              <small className="d-block font-weight-bold">
                {item.city}, {item.state}
              </small>
            )}
          </div>
        </div>
      </a>
      <div className="icon_box">
        {connectionIcon(item, openConfirmationPopup, onclickIconConnection)}
      </div>
    </div>
  ) : (
    <div className="list-group-item list-group-item-action px-2">
      <div className="media">
        {item.profile_picture ? (
          <img
            src={item.profile_picture}
            className="img-fluid mr-3 rounded-circle thumb"
            alt={item.first_name}
          />
        ) : (
          <Logo className="mr-3 rounded-circle thumb" />
        )}
        <div className="media-body">
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="mb-0 h6">
              {item.first_name} {item.last_name}
            </div>
            {connectionIcon(item, openConfirmationPopup, onclickIconConnection)}
          </div>
          <small className="mb-1 d-block">{item.interested_in}</small>
          {item.country && (
            <small className="d-block font-weight-bold">
              {item.city}, {item.state}
            </small>
          )}
        </div>
      </div>
    </div>
  );
}
