import React from 'react';
import step1 from '../../images/step-1.webp'

const UserDetails = ({ nextStep, email_verified }) => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-6 text-left mt-0 mt-lg-5 mb-5 mb-lg-0">
            <h2
              className="mb-3"
              style={{ fontSize: "clamp(28px, 5vw, 36px)", fontWeight: "700" }}
            >
              Let's complete your profile so you can start connecting.
            </h2>

            <p style={{ fontSize: "clamp(15px, 5vw, 18px)" }}>
              3For3 Networking is a business mentorship and digital networking
              group. We have designed the platform for professionals, business
              owners and students.
            </p>
            {/* {email_verified === undefined || email_verified === null ? (
              <React.Fragment>
                <button
                  className="mt-3 btn btn-primary disabled rounded-0 px-4"
                  aria-disabled="true"
                  disabled
                >
                  Get Started
                </button>
                <p
                  className="text-danger mt-2 fs-6"
                  style={{ fontSize: "14px" }}
                >
                  * Please verify your email first to continue.
                </p>
              </React.Fragment>
            ) : (
              <button
                onClick={nextStep}
                className="mt-3 btn btn-primary rounded-0 px-4"
              >
                Get Started
              </button>
            )} */}
            <button
              onClick={nextStep}
              className="mt-3 btn btn-primary rounded-0 px-4"
            >
              Get Started
            </button>
          </div>

          <div className="col-12 col-lg-5 offset-lg-1">
            <img src={step1} className="img-fluid" />
          </div>
        </div>
      </React.Fragment>
    );
}

export default UserDetails;