import React, { useState } from "react";
import "./no-more-profile.styles.scss";
import { useSelector } from 'react-redux';
import axios from 'axios';
import clientConfig from '../../env';
import { toast } from 'react-toastify';

const NoMoreProfile = ({ sendDataToParentNoMoreData }) => {

  const userId = useSelector( (state) => state.user.userId );
  const token = useSelector( (state) => state.user.token );
  const[isDisabled, setIsDisabled] = useState(false);

  const reset_user_temp_list = (e) => {
    e.preventDefault();
    setIsDisabled(!isDisabled);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Barer ${token}`,
    }

    axios.post(`${clientConfig.siteUrl}wl/v1/reset-temp-user`, { "id": userId }, {
        headers: headers
    }).then( response => {
        if(response.status === 200) {
          let type = response.data.type;
          if(type === "success") {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          setIsDisabled(!isDisabled);
          sendDataToParentNoMoreData(false)
        }
    }).catch( error => console.error(error) );
  }

  return (
    <div className="user_popup_container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <div className="nomore_profile">
              <h3 className="message_title">No matched profile found</h3>
              <p className="message_note">
                You have seen all profiles on the{" "}
                <strong>3For3 Networking</strong> platform, please reset to
                start again.
              </p>
              <p className="message_note">New profiles are created everyday.</p>

              <button
                disabled={isDisabled}
                onClick={reset_user_temp_list}
                className="btn-reset_profile"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMoreProfile;
