import { createStore, applyMiddleware } from 'redux';
// import logger from 'redux-logger';

import rootReducer from './root-reducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

const persistConfig = {
    key: "root",
    storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [];

const store = createStore(persistedReducer, applyMiddleware(...middlewares));
// without persist
// const store = createStore(rootReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);

export default store; 