import React, { useState } from 'react';
import './support.styles.scss';

import axios from 'axios';
import clientConfig from '../../env';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { formatPhoneNumber } from '../../utilities/utilities'

//components
import MainContainer from '../../components/main-container/main-container.component';

const Support = () => {

  const token = useSelector((state) => state.user.token);
  const userEmail = useSelector((state) => state.user.email);
  const userFirstname = useSelector( (state) => state.user.firstName );
  const userLastname = useSelector( (state) => state.user.lastName );
  const userPhone = useSelector( (state) => state.user.phone );

  const [firstName, setFirstName] = useState(userFirstname);
  const [lastName, setLastName] = useState(userLastname);
  const [email, setEmail] = useState(userEmail);
  const [phone, setPhone] = useState(userPhone);
  const [message, setMessage] = useState("");
  const [isEmailEditable, setIsEmailEditable] = useState(false)
  const [isPhoneEditable, setIsPhoneEditable] = useState(false)
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const clearState = () => {
    Array.from(document.querySelectorAll(".form-control")).forEach(
      input => (input.value = "")
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabledSubmitBtn(true)
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Barer ${token}`,
    }

    let databody = {
      email: email,
      username: `${firstName} ${lastName}`,
      message: message,
      phone: phone
    }

    axios.post(`${clientConfig.siteUrl}wl/v1/send-support-request`, databody, {
      headers: headers
    }).then(response => {
      if (response.status === 200) {
        toast.success(response.data.message);
        setFirstName(userFirstname);
        setLastName(userLastname)
        setPhone(userPhone)
        setEmail(userEmail)
        setMessage("")
        clearState();
      } else {
        toast.error(response.data.error);
      }
      setDisabledSubmitBtn(false);
    }).catch(error => {
      console.log(error);
      setDisabledSubmitBtn(false);
    });
  }

  const isDisabled =
    firstName && lastName && email && phone && message && !disabledSubmitBtn ? false : true;

  return (
    <MainContainer>
      <div className="connections-container py-3 py-md-4 px-0 px-md-3">
        <div className="row">
          <div className="col-12">
            <h1 className="title mb-4">Support</h1>
          </div>

          <div className="col-12 col-lg-8">
            <form onSubmit={handleSubmit} id="supportForm">
              <div className="form-row">
                <div className="col">
                  <label htmlFor="firstname">
                    First Name <span>*</span>
                  </label>
                  <input
                    required={true}
                    type="text"
                    id="firstname"
                    className="form-control"
                    placeholder="First name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="col">
                  <label htmlFor="lastname">
                    Last Name <span>*</span>
                  </label>
                  <input
                    required={true}
                    type="text"
                    id="lastname"
                    className="form-control"
                    placeholder="Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group my-3">
                <label htmlFor="email">
                  Email <span>*</span>{" "}
                  <span
                    className="readonly"
                    onClick={() => setIsEmailEditable(!isEmailEditable)}
                  >
                    {isEmailEditable ? "Update" : "Edit"}
                  </span>
                </label>
                <input
                  required={true}
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  placeholder="Enter your Email"
                  onChange={(e) => setEmail(e.target.value)}
                  readOnly={!isEmailEditable}
                />
              </div>

              <div className="form-group my-3">
                <label htmlFor="phone">
                  Phone <span>*</span>{" "}
                  <span
                    className="readonly"
                    onClick={() => setIsPhoneEditable(!isPhoneEditable)}
                  >
                    {isPhoneEditable ? "Update" : "Edit"}
                  </span>
                </label>
                <input
                  required={true}
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="Enter your Phone number"
                  value={formatPhoneNumber(phone)}
                  onChange={(e) => setPhone(e.target.value)}
                  readOnly={!isPhoneEditable}
                />
              </div>

              <div className="form-group">
                <label htmlFor="message">
                  Message <span>*</span>
                </label>
                <textarea
                  required={true}
                  className="form-control"
                  id="message"
                  rows="5"
                  placeholder="Enter your Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>

              <button
                type="submit"
                className="btn btn-primary rounded-0 px-5"
                disabled={isDisabled}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </MainContainer>
  );

}

export default Support;